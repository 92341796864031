import { 
    findAllAcademicConceptPaged, 
    findAcademicConceptById, 
    findAllAcademicConcept, 
    findAllAcademicConceptPagedLikeName 
} from "../../_services/academic_concept.service";

export const findAllAcademicConceptPagedEvent = async (page, limit = 8) => {
    return await findAllAcademicConceptPaged(page, limit);
}

export const findAcademicConceptByIdEvent = async (id) => {
    return await findAcademicConceptById(id);
}

export const findAllAcademicConceptEvent = async () => {
    return await findAllAcademicConcept();
}

export const findAllAcademicConceptPagedLikeNameEvent = async (page, limit = 8, name) => {
    return await findAllAcademicConceptPagedLikeName(page, limit, name);
}