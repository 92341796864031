import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/competency';

export const findCompetencyById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllCompetency = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const changeStateById = async (id, state) => {
    return await handleFetch(`${BASE_PATH}/change-state/${id}/${state}`, GET_OPTIONS);
}

export const createCompetency = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editCompetency = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllCompetencyPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllCompetencyPagedLikeName = async (page, limit, name) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}/name/${name}`, GET_OPTIONS);
}

export const createByDuplicateCompetency = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-duplication`, POST_OPTIONS, body);
}