export function showAlert(id, message){
    document.getElementById(`${id}_msg`).innerHTML = message;
    document.getElementById(id).style.display = 'flex';      
    setTimeout(() => {
      dismissAlert(id);
    }, 4000);
}

export function dismissAlert(id){
    document.getElementById(id).style.display = 'none';
    document.getElementById(`${id}_msg`).innerHTML = '';
}

export function alertSuccess(msg){
    showAlert('vp_alert_success', msg);
}

export function alertError(msg){
    showAlert('vp_alert_danger', msg);
}