import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/academic-concept';

export const findAcademicConceptById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const changeStateById = async (id, state) => {
    return await handleFetch(`${BASE_PATH}/change-state/${id}/${state}`, GET_OPTIONS);
}

export const findAllAcademicConcept = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createAcademicConcept = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editAcademicConcept = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllAcademicConceptPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllAcademicConceptPagedLikeName = async (page, limit, name) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}/name/${name}`, GET_OPTIONS);
}