import { findAllCompetencyPaged, findCompetencyById, findAllCompetency, findAllCompetencyPagedLikeName } from "../../_services/competency.service";

export const findAllCompetencyPagedEvent = async (page, limit = 8) => {
    return await findAllCompetencyPaged(page, limit);
}

export const findCompetencyByIdEvent = async (id) => {
    return await findCompetencyById(id);
}

export const findAllCompetencyEvent = async () => {
    return await findAllCompetency();
}

export const findAllCompetencyPagedLikeNameEvent = async (page, limit = 8, name) => {
    return await findAllCompetencyPagedLikeName(page, limit, name);
}