import { editActivityFormTemplate, createActivityFormTemplate } from "../../_services/activity_form_template.service";
import { validateActivityFormTemplate } from "./model";

export const createActivityFormTemplateEvent = async (body) => {
    validateActivityFormTemplate(body);
    return await createActivityFormTemplate(body);
}

export const editActivityFormTemplateEvent = async (id, body) => {
    validateActivityFormTemplate(body);
    return await editActivityFormTemplate(id, body);
}