import { editEvaluation, createEvaluation, evaluate, changeOrderEvaluation, copyByTermEvaluation } from "../../_services/evaluation.service";
import { getUserId } from "../../_utils/storage_handler";
import { validateEvaluation } from "./model";

export const createEvaluationEvent = async (body) => {
    validateEvaluation(body);
    return await createEvaluation(body);
}

export const evaluateEvent = async (body) => {
    body.updated_by = getUserId();
    validateEvaluation(body);
    return await evaluate(body);
}

export const changeOrderEvaluationEvent = async (body) => {
    return await changeOrderEvaluation(body);
}

export const editEvaluationEvent = async (id, body) => {
    validateEvaluation(body);
    return await editEvaluation(id, body);
}

export const copyByTermEvaluationEvent = async (from, to, form) => {
    return await copyByTermEvaluation(from, to, form);
}