import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/user';

export const findUserById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllUser = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const createUserAndPerson = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-person`, POST_OPTIONS, body);
}

export const editUser = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const editUserPassword = async (body) => {
    return await handleFetch(`${BASE_PATH}/edit-password`, PUT_OPTIONS, body);
}

export const findAllUserPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}