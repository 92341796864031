import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SemestersMenu from "./semesters_menu";
import { useHistoryForms } from "./hook";
import CardInfo from "./card_info";

function Info(){

    const hook = useHistoryForms();

    return(
        <Container fluid>
            <Row>
                <Col lg={2}>
                    <SemestersMenu
                        hook = { hook }
                    />
                </Col>
                <Col lg={10}>
                    {
                        hook.data.map((d, key) => {
                            return(
                                <div key={`card_${key}`} style={{ margin: '10px 10px' }}>
                                    <CardInfo
                                        data={d}
                                    />
                                </div>
                            );
                        })
                    }                    
                </Col>
            </Row>
        </Container>
    );
}

export default Info;