import React from "react";
import { buildEmptyUser, transformEntityUser } from "../../_events/users/model";
import { buildEmptyPerson, transformEntityPerson } from "../../_events/person/model";
import { findUserByIdEvent } from "../../_events/users/find.event";
import { findPersonByIdEvent } from "../../_events/person/find.event";
import { editPersonEvent } from "../../_events/person/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { getUserId } from "../../_utils/storage_handler";
import { editUserEvent } from "../../_events/users/create.event";

export const useEditProfile = () => {
    const uuid = getUserId();

    const [ entity, setEntity ] = React.useState(buildEmptyUser());
    const [ person, setPerson ] = React.useState(buildEmptyPerson());

    const edit = () => {
        editUserEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const editPerson = () => {
        editPersonEvent(entity.person.uuid, person)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findUserByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityUser(json.data));
                findPersonByIdEvent(json.data.person?.uuid)
                .then(json2 => {
                    setPerson(transformEntityPerson(json2.data));
                })
                .catch(err => {
                    alertError(err.message);
                })
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, person, setPerson, uuid, edit, editPerson };
}