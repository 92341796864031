import { addStudentToFormEvent } from "../../_events/activity_form/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useAddStudentToForm = () => {

    const addStudent = (student, form, loadFunc = () => {}) => {
        addStudentToFormEvent({
            student,
            form,
        })
        .then(json => {
            alertSuccess("Agregado");
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { addStudent };
}