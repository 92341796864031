import { findAllObservation, findAllObservationPaged, findObservationById, findObservationByStudentAndTerm } from "../../_services/observation.service";

export const findAllObservationPagedEvent = async (page, limit = 8) => {
    return await findAllObservationPaged(page, limit);
}

export const findObservationByIdEvent = async (id) => {
    return await findObservationById(id);
}

export const findObservationByStudentAndTermEvent = async (student, term) => {
    return await findObservationByStudentAndTerm(student, term);
}

export const findAllObservationEvent = async () => {
    return await findAllObservation();
}