import React from "react";
import { findAllAcademicPartWithFormByStudentFormGroupedByTermEvent } from "../../_events/academic_parts/find.event";
import { alertError } from "../../_utils/alerts";

export const useAcademicPartWithFormByStudentFormGroupedByTerm = (sf) => {

    const [data, setData] = React.useState({terms: []});
    const [ selected, setSelected ] = React.useState(sf);

    const loadData = (i) => {
        findAllAcademicPartWithFormByStudentFormGroupedByTermEvent(i)
        .then(json => {
            setData(json.data);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(selected.uuid);
    // eslint-disable-next-line
    }, []);

    return { data, selected, setData, setSelected, loadData };
}