import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link } from "react-router-dom";
import { path_modules } from "../../../app_modules";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTypePersonAll } from "../../../../_hooks/type_person/useAllTypePErson.hook";
import ObjectSelect from "../../../../widgets/select/object_select";
import { useAllRoles } from "../../../../_hooks/roles/useAllRoles.hook";
import { useCreateCreateUserRequest } from "../../../../_hooks/create_user_request/use-create-create-user-request.hook";

function CreateUserRequestForm(){

    const creationHook = useCreateCreateUserRequest();

    const allTypesHook = useTypePersonAll();
    const allRolesHook = useAllRoles();

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.admin.children.users.fullPath}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                    <span className="vp-title">Crear solicitud de registro de usuario</span>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='form-label'>Nombre completo</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity?.name }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.name = e.target.value;
                                    creationHook.setEntity(newEnt);                                  
                                }}
                            />
                            <div className='form-label'>Correo</div>
                            <input 
                                className='vp-input' 
                                type='email'
                                value={ creationHook.entity?.email }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.email = e.target.value;
                                    creationHook.setEntity(newEnt);                              
                                }}
                            />
                            <div className='form-label'>Tipo</div>
                            <ObjectSelect
                                value={ creationHook.entity?.type }
                                options={allTypesHook.data.map( d => ({ value: d.cod, label: d.name }))}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.type = e;
                                    creationHook.setEntity(newEnt);                               
                                }}
                            />
                        </Col>
                        <Col lg={6}>
                        <div className='form-label'>Usuario</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity.login }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.login = e.target.value;
                                    creationHook.setEntity(newEnt);                             
                                }}
                            />
                            <div className='form-label'>Rol</div>
                            <ObjectSelect
                                value={ creationHook.entity.rol }
                                options={allRolesHook.data.map( d => ({ value: d.cod, label: d.name }))}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.rol = e;
                                    creationHook.setEntity(newEnt);                          
                                }}
                            />
                        </Col>
                        <Col>
                            <Button onClick={ () => creationHook.create() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                Crear solicitud
                            </Button>
                            {
                                creationHook.requestLink && 
                                <div style={{ marginTop: '20px' }}>
                                    <div style={{ fontSize: '1.1em', fontWeight: 'bold' }} className="flex-col flex-center">
                                        <p>Solicitud generada.</p>
                                        <p>Link de registro: </p>
                                    </div>
                                    <div style={{ fontSize: '0.8em', fontColor: 'grey' }} className="flex-col flex-center">
                                        {creationHook.requestLink}
                                    </div>
                                    <div style={{ fontSize: '0.9em', fontWeight: 'bold' }} className="flex-col flex-center">
                                        Te damos una pista del correo que debes enviarle a la persona para que complete el registro: 
                                    </div>
                                    <div style={{ fontSize: '0.8em', marginTop: '10px', padding: '10px 10px', border: 'solid 1px #000', borderRadius: '20px' }} className="flex-col">
                                        <p>Hola {creationHook.entity.name},</p>
                                        <p>Se generó una solicutd de regitro de usuario para ti en software PRACTICUM.</p>
                                        <p>Por favor, completa el registro ingresando a este link:</p>
                                        <p>{creationHook.requestLink}</p>
                                        <p>Saludos cordiales.</p>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainContent>
    );
}

export default CreateUserRequestForm;