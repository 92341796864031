import { editAcademicConcept, createAcademicConcept, changeStateById } from "../../_services/academic_concept.service";
import { validateAcademicConcept } from "./model";

export const createAcademicConceptEvent = async (body) => {
    validateAcademicConcept(body);
    return await createAcademicConcept(body);
}

export const editAcademicConceptEvent = async (id, body) => {
    validateAcademicConcept(body);
    return await editAcademicConcept(id, body);
}

export const changeStateByIdAcademicConceptEvent = async (id, state) => {
    return await changeStateById(id, state);
}