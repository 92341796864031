import React from "react";
import { findAllAcademicPartWithFormNotIncludeBySemesterEvent } from "../../_events/academic_parts/find.event";
import { alertError } from "../../_utils/alerts";

export const useAcademicPartAllFormNotIncludeBySemester = (semester, term) => {

    const [data, setData] = React.useState([]);

    const loadData = (s, t) => {
        findAllAcademicPartWithFormNotIncludeBySemesterEvent(s, t)
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(semester, term);
    }, [semester, term]);

    return { data, loadData };
}