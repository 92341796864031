import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/access-rol';

export const findAccessRolById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllAccessRolByRol = async (rol) => {
    return await handleFetch(`${BASE_PATH}/all/rol/${rol}`, GET_OPTIONS);
}

export const updateAccessRol = async (body) => {
    return await handleFetch(`${BASE_PATH}/update`, POST_OPTIONS, body);
}

export const findAllAccessRol = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createAccessRol = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editAccessRol = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllAccessRolPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}