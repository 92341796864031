import { findAllActivityFormPaged, findActivityFormById, findAllActivityForm } from "../../_services/activity_form.service";

export const findAllActivityFormPagedEvent = async (page, limit = 8) => {
    return await findAllActivityFormPaged(page, limit);
}

export const findActivityFormByIdEvent = async (id) => {
    return await findActivityFormById(id);
}

export const findAllActivityFormEvent = async () => {
    return await findAllActivityForm();
}