import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link } from "react-router-dom";
import { path_modules } from "../../../app_modules";
import { useQuery } from "../../../../_utils/url_query_hook";
import { useAcademicPartAllWithTemplatesBySemester } from "../../../../_hooks/academic_part/useAcademicPartAllWithTemplatesBySemester.hook";
import AddActivityModal from "./add_activity_modal";
import InfoForm from "./info_form";
import { useFormTemplateCreateEdit } from "../../../../_hooks/form_template/useFormTemplateCreateEdit.hook";
import { Button } from "react-bootstrap";

function FormTemplatePage(){

    const query = useQuery();
    const id = query.get('id');
    const semesterName = query.get('name');

    const activitiesHook = useAcademicPartAllWithTemplatesBySemester(id);
    const creationHook = useFormTemplateCreateEdit(id);

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.operation.children.semesters.fullPath}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                    <span className="vp-title">Planilla para semestre {semesterName}</span>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%' }}>                
                {
                    creationHook.entity.uuid === undefined ?
                    <Button onClick={() => creationHook.create()} variant="success" size="sm">
                        Crear nuevo template
                    </Button>
                    :
                    <div>
                        <AddActivityModal
                            semester={id}
                            formId={creationHook.entity.uuid}
                            loadFunc={ () => activitiesHook.loadData(id)}
                        />
                        <InfoForm 
                            formId={creationHook.entity.uuid}
                            dataHook = {activitiesHook}
                            loadFunc={ () => activitiesHook.loadData(id)}
                        />
                    </div>                    
                }
            </div>
        </MainContent>
    );
}

export default FormTemplatePage;