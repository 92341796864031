import { findAllActivityFormTemplatePaged, findActivityFormTemplateById, findAllActivityFormTemplate } from "../../_services/activity_form_template.service";

export const findAllActivityFormTemplatePagedEvent = async (page, limit = 8) => {
    return await findAllActivityFormTemplatePaged(page, limit);
}

export const findActivityFormTemplateByIdEvent = async (id) => {
    return await findActivityFormTemplateById(id);
}

export const findAllActivityFormTemplateEvent = async () => {
    return await findAllActivityFormTemplate();
}