import React from "react";
import { findObservationByStudentAndTermEvent } from "../../_events/observation/find.event";
import { createObservationEvent, editObservationEvent } from "../../_events/observation/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { buildEmptyObservation, transformEntityObservation } from "../../_events/observation/model";

export const useCompetencyCreateEdit = () => {

    const [ entity, setEntity ] = React.useState(buildEmptyObservation());

    const create = () => {
        createObservationEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyObservation());
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = (uuid) => {
        editObservationEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const load = (student, term) => {
        findObservationByStudentAndTermEvent(student, term)
        .then(json => {
            setEntity(transformEntityObservation(json.data));
        })
        .catch(err => {
            alertError(err.message);
        })
    }

    return { entity, setEntity, create, edit, load };
}