import LoginPage from './login';

/** Template */
import ContentTemplate from './content/content_template';

/** Template sub modules */
import AcademicConceptsPage from './content/parametrization/academic-concepts';
import UsersPage from './content/admin/users';
import AcademicConceptsForm from './content/parametrization/academic-concepts/form';
import CompetencyGroupPage from './content/parametrization/competency_group';
import CompetencyGroupForm from './content/parametrization/competency_group/form';
import CompetencyPage from './content/parametrization/competency';
import CompetencyForm from './content/parametrization/competency/form';
import CompetencyActivityPage from './content/parametrization/competency_activity';
import CompetencyActivityForm from './content/parametrization/competency_activity/form';
import StudentPage from './content/operation/students';
import StudentForm from './content/operation/students/form';
import SemesterPage from './content/operation/semesters';
import SemesterForm from './content/operation/semesters/form';
import UserForm from './content/admin/users/form';
import FormTemplatePage from './content/operation/form_template';
import FormTemplateModule from './content/operation/form/form_template';
import ActiveFormsPage from './content/operation/form/active_forms';
import HistoryFormsPage from './content/operation/form/history_forms';
import FormPage from './content/operation/form/form';
import ProfilePage from './content/admin/profile';
import EditFormPage from './content/operation/form/edit_form';
import StudentsFormPage from './content/operation/form/edit_students';
import AuthWrapper from '../hoc/auth_wrapper';
import RolesPage from './content/admin/roles';
import RegisterPage from './register';
import CreateUserRequestForm from './content/admin/users/request-form';
import EndRegisterPage from './end-register';

const loginPath = '';
const registerPath = 'register';
const endRegisterPath = 'end-register';
const contentPath = 'content';

export const path_modules = {
    login: {
        label: 'Login',
        path: `${loginPath}`,
        fullPath: `/${loginPath}`,
    },
    register: {
        label: 'Register',
        path: `${registerPath}`,
        fullPath: `/${registerPath}`,
    },
    end_register: {
        label: 'End Register',
        path: `${endRegisterPath}`,
        fullPath: `/${endRegisterPath}`,
    },
    content: {
        label: 'Content',
        path: `${contentPath}`,
        fullPath: `/${contentPath}`,
        children: {
            parametrization: { 
                label: 'Parametrización',
                path: 'param',
                fullPath: `/${contentPath}/param`,
                auth: ['CO-AC', 'GR-CO', 'COMPE'],
                children: {
                    academic_concepts: { 
                        label: 'Concep. Académicos',
                        path: 'param/academic-concepts',
                        fullPath: `/${contentPath}/param/academic-concepts`,
                        auth: ['CO-AC'],
                    },
                    competency_groups: { 
                        label: 'Grup. Competencia',
                        path: 'param/competency-groups',
                        fullPath: `/${contentPath}/param/competency-groups`,
                        auth: ['GR-CO'],
                    },
                    competency: { 
                        label: 'Competencias',
                        path: 'param/competency',
                        fullPath: `/${contentPath}/param/competency`,
                        auth: ['COMPE'],
                    },
                }
            },
            operation: { 
                label: 'Operaciones',
                path: 'operation',
                fullPath: `/${contentPath}/operation`,
                auth: ['STUDE', 'FORMS', 'TERMS'],
                children: {
                    students: { 
                        label: 'Estudiantes',
                        path: 'operation/students',
                        fullPath: `/${contentPath}/operation/students`,
                        auth: ['STUDE'],
                    },
                    forms: { 
                        label: 'Planillas',
                        path: 'operation/forms',
                        fullPath: `/${contentPath}/operation/forms`,
                        auth: ['FORMS'],
                    },
                    semesters: { 
                        label: 'Semestres',
                        path: 'operation/semesters',
                        fullPath: `/${contentPath}/operation/semesters`,
                        auth: ['TERMS'],
                    },
                }
            }, 
            admin: { 
                label: 'Administración',
                path: 'admin',
                fullPath: `/${contentPath}/admin`,
                auth: ['PROFI', 'ROLES', 'USERS'],
                children: {
                    profile: { 
                        label: 'Perfil',
                        path: 'admin/profile',
                        fullPath: `/${contentPath}/admin/profile`,
                        auth: ['PROFI'],
                    },
                    roles: { 
                        label: 'Roles',
                        path: 'admin/rol',
                        fullPath: `/${contentPath}/admin/rol`,
                        auth: ['ROLES'],
                    },
                    users: { 
                        label: 'Usuarios',
                        path: 'admin/users',
                        fullPath: `/${contentPath}/admin/users`,
                        auth: ['USERS'],
                    },
                }
            },            
        }
    },
};

export const paths = {
    academic_concepts_form: `/${contentPath}/param/academic-concepts-form`,
    competency_group_form: `/${contentPath}/param/competency-groups-form`,
    competency_form: `/${contentPath}/param/competency-form`,
    competency_activity: `/${contentPath}/param/competency-activity`,
    competency_activity_form: `/${contentPath}/param/competency-activity-form`,
    evaluation_type: `/${contentPath}/param/evaluation-type`,
    evaluation_type_form: `/${contentPath}/param/evaluation-type-form`,
    student_form: `/${contentPath}/param/student-form`,
    semester_form: `/${contentPath}/param/semester-form`,
    user_form: `/${contentPath}/admin/user-form`,
    create_user_request_form: `/${contentPath}/admin/user-request-form`,
    form_template: `/${contentPath}/operation/form-template`,
    forms_active: `/${contentPath}/operation/forms`,
    forms_history: `/${contentPath}/operation/forms/history`,
    edit_form: `/${contentPath}/operation/forms/edit-form`,
    students_form: `/${contentPath}/operation/forms/student-form`,
    form: `/${contentPath}/operation/forms/form`,
}

export const router_modules = [
    {
        path: `${path_modules.login.path}`,
        component: LoginPage,
    },
    {
        path: `${path_modules.register.path}`,
        component: RegisterPage,
    },
    {
        path: `${path_modules.end_register.path}`,
        component: EndRegisterPage,
    },
    {
        path: `${path_modules.content.path}`,
        component: AuthWrapper(ContentTemplate),
        children: [
            {                
                path: `${path_modules.content.children.parametrization.children.academic_concepts.path}`,
                component: AcademicConceptsPage,
            },
            {                
                path: `${paths.academic_concepts_form}`,
                component: AcademicConceptsForm,
            },
            {                
                path: `${path_modules.content.children.parametrization.children.competency_groups.path}`,
                component: CompetencyGroupPage,
            },
            {                
                path: `${paths.competency_group_form}`,
                component: CompetencyGroupForm,
            },
            {                
                path: `${path_modules.content.children.parametrization.children.competency.path}`,
                component: CompetencyPage,
            },
            {                
                path: `${paths.competency_form}`,
                component: CompetencyForm,
            },
            {                
                path: `${paths.competency_activity}`,
                component: CompetencyActivityPage,
            },
            {                
                path: `${paths.competency_activity_form}`,
                component: CompetencyActivityForm,
            },
            {                
                path: `${path_modules.content.children.operation.children.students.path}`,
                component: StudentPage,
            },
            {                
                path: `${paths.student_form}`,
                component: StudentForm,
            },
            {                
                path: `${path_modules.content.children.operation.children.semesters.path}`,
                component: SemesterPage,
            },
            {                
                path: `${paths.semester_form}`,
                component: SemesterForm,
            },
            {                
                path: `${path_modules.content.children.admin.children.users.path}`,
                component: UsersPage,
            },
            {                
                path: `${paths.user_form}`,
                component: UserForm,
            },
            {                
                path: `${paths.create_user_request_form}`,
                component: CreateUserRequestForm,
            },
            {                
                path: `${path_modules.content.children.admin.children.roles.path}`,
                component: RolesPage,
            },
            {                
                path: `${path_modules.content.children.admin.children.profile.path}`,
                component: ProfilePage,
            },
            {                
                path: `${paths.form_template}`,
                component: FormTemplatePage,
            },
            {                
                path: `${path_modules.content.children.operation.children.forms.path}`,
                component: FormTemplateModule,
                children: [
                    {                
                        path: `${paths.forms_active}`,
                        component: ActiveFormsPage,
                    },
                    {                
                        path: `${paths.forms_history}`,
                        component: HistoryFormsPage,
                    },
                    {                
                        path: `edit-form`,
                        component: EditFormPage,
                    },
                    {                
                        path: `student-form`,
                        component: StudentsFormPage,
                    },
                    {                
                        path: `form`,
                        component: FormPage,
                    },
                ]
            },
        ],
    },
];