import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useUser } from "../../../../../_hooks/user/use-user.hook";

function UpdatedByModal({ updatedBy }){

    const [show, setShow] = React.useState(false);

    const userHook = useUser();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        userHook.loadData(updatedBy);
        setShow(true);
    }

    return(
        <div>
            <span style={{ border: 'solid 1px green', fontSize: '0.5em', cursor: 'pointer' }} onClick={handleShow} variant="success" size="sm">
                Act. por
            </span>
            <Modal show={show} onHide={handleClose} size="xl">
                <ModalBody>
                    <div style={{ fontWeight: 'bold' }}>Actualizado por:</div>
                    <div style={{ fontWeight: 'bold' }}>{userHook.data?.person?.name}</div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default UpdatedByModal;