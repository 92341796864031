import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import StudentsMenu from "./students_menu";
import { useAcademicPartWithFormByStudentFormGroupedByTerm } from "../../../../../_hooks/academic_part/useAcademicPartWithFormByStudentFormGroupedByTerm.hook";
import TotalTermInfo from "./total_term_info";
import { TotalStatus } from "./total_status";

function TotalInfo({ students }){

    const studentFormEvaluationsHook =  useAcademicPartWithFormByStudentFormGroupedByTerm(students ? students.length > 0 ? {...students[0]} : '' : '');


    return(
        <Container fluid>
            <Row>
                <Col lg={2}>
                    <StudentsMenu
                        students={students}
                        selected={studentFormEvaluationsHook.selected.uuid}
                        loadData={(studentForm) => {
                            studentFormEvaluationsHook.setSelected(studentForm);
                            studentFormEvaluationsHook.loadData(studentForm.uuid);
                            //console.log(`ReCargar ${studentForm.uuid}, ${term}`);
                        }}
                    />
                </Col>
                <Col lg={10}>
                    <hr />
                    <Container fluid >
                        <Row>
                            <Col style={{ margin: '20px 0' }} lg={12}>
                                <TotalStatus terms={studentFormEvaluationsHook.data.terms} />
                            </Col>
                        </Row>
                        <Row>
                            {
                                studentFormEvaluationsHook.data.terms.map((d, key) => {
                                    return(                                        
                                        <Col key={`total_${key}`} lg={4}>
                                            <TotalTermInfo
                                                data={d.academic_part}
                                                term={d.term}
                                            />
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Container>            
                </Col>
            </Row>
        </Container>
    );
}

export default TotalInfo;