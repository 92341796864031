import React from "react";
import { findFormByIdWithAllEvent } from "../../_events/form/find.event";
import { alertError } from "../../_utils/alerts";

export const useFormById = (id) => {

    const [data, setData] = React.useState(null);

    const loadData = (i) => {
        findFormByIdWithAllEvent(i)
        .then(json => {
            setData(json.data);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(id)
    }, [id]);

    return { data, loadData };
}