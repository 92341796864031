import React from "react";
import MainPagination from "../pagination/main_pagination";
import MainTable from "./main_table";

function PagedTable({ columns = [], data = [], dataPerPage = 8, currentPage, total, count = 6, onChangePage = (page) => {} }){

    return(
        <div style={{ width: '100%' }}>
            <MainPagination currentPage={currentPage} dataPerPage={dataPerPage} total={total} count={count} onChangePage = { (page) => onChangePage(page)} />
            <MainTable columns={columns} data={data} />
            <MainPagination currentPage={currentPage}dataPerPage={dataPerPage}  total={total} count={count} onChangePage = { (page) => onChangePage(page)} />
        </div>
    );
}

export default PagedTable;
