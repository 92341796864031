import React from "react";
import { findAllStundentNotInActiveFormPagedEvent } from "../../_events/person/find.event";
import { alertError } from "../../_utils/alerts";

export const useStudentNotInActiveFormPaged = (page = 0, limit = 8) => {

    const [data, setData] = React.useState([]);

    const loadData = (p, l) => {
        findAllStundentNotInActiveFormPagedEvent(p, l)
        .then(json => {
            setData(json.paged);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(page, limit);
    }, [page, limit]);

    return { data, loadData };
}