import { 
    findAllStudentFormPaged,  
    findStudentFormById, 
    findAllStudentForm, 
    findStudentFormByIdWithEvaluations,
    findStudentFormByIdWithEvaluationsByTerm,
    findAllStudentFormByFormPaged,
    downloadtudentFormProceduresExcelByForm,
    downloadtudentFormEvaluationsExcelByForm,
    downloadtudentFormProceduresExcelByTwoForms
} from "../../_services/student_form.service";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const findAllStudentFormPagedEvent = async (page, limit = 8) => {
    return await findAllStudentFormPaged(page, limit);
}

export const findAllStudentFormByFormPagedEvent = async (form, page, limit = 8) => {
    return await findAllStudentFormByFormPaged(form, page, limit);
}

export const findStudentFormByIdEvent = async (id) => {
    return await findStudentFormById(id);
}

export const findStudentFormByIdWithEvaluationsEvent = async (id) => {
    return await findStudentFormByIdWithEvaluations(id);
}

export const findStudentFormByIdWithEvaluationsByTermEvent = async (id, term) => {
    return await findStudentFormByIdWithEvaluationsByTerm(id, term);
}

export const findAllStudentFormEvent = async () => {
    return await findAllStudentForm();
}

export const downloadtudentFormProceduresExcelByFormEvent = async (form) => {
    const json = await downloadtudentFormProceduresExcelByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Consolidado_${new Date().toISOString()}`);
    }
}

export const downloadtudentFormProceduresExcelByTwoFormsEvent = async (form1, form2) => {
    const json = await downloadtudentFormProceduresExcelByTwoForms(form1, form2);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Anualidad`);
    }
}

export const downloadtudentFormEvaluationsExcelByFormEvent = async (form) => {
    const json = await downloadtudentFormEvaluationsExcelByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Calificaciones_${new Date().toISOString()}`);
    }
}