import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptyAcademicConcept, transformEntityAcademicConcept } from "../../_events/academic_concept/model";
import { findAcademicConceptByIdEvent } from "../../_events/academic_concept/find.event";
import { createAcademicConceptEvent, editAcademicConceptEvent } from "../../_events/academic_concept/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useAcademicConceptCreateEdit = () => {

    const query = useQuery();
    const uuid = query.get("id");

    const [ entity, setEntity ] = React.useState(buildEmptyAcademicConcept());

    const create = () => {
        createAcademicConceptEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyAcademicConcept());
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editAcademicConceptEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findAcademicConceptByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityAcademicConcept(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit };
}