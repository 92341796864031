import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS, DELETE_OPTIONS } from './base.service';

const BASE_PATH = '/activity-form';

export const findActivityFormById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const deleteActivityFormByFormAndActivity = async (form, activity) => {
    return await handleFetch(`${BASE_PATH}/form/activity/${form}/${activity}`, DELETE_OPTIONS);
}

export const findAllActivityForm = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createActivityForm = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const addActivityFormActivity = async (body) => {
    return await handleFetch(`${BASE_PATH}/add-activity`, POST_OPTIONS, body);
}

export const addStudentToForm = async (body) => {
    return await handleFetch(`${BASE_PATH}/add-student-form`, POST_OPTIONS, body);
}

export const editActivityForm = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllActivityFormPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}