import { useActiveFormNotId } from "../../_hooks/form/useActiveFormNotId.hook";
import ObjectMultiSelect from "./object_multi_select";

export default function ActiveFormMultiSelect({ form, value, onChange }) {

    const data = useActiveFormNotId(form);

    const buildOptions = () => {
        let options = data.data.map( d => ({ value: d.uuid, label: `${d.semester?.name} semestre (${d.date_created ? d.date_created.split("T")[0] : ''})` }) );
        //options.unshift({ value: '', label: 'Selecciona...' });
        return options;
    }

    return(
        <ObjectMultiSelect 
            value={value}
            onChange={onChange}
            options={buildOptions()}
        />
    );
}