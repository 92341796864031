import { copyByTermEvaluationEvent } from "../../_events/evaluation/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const copyEvaluationFromTerm = (loadFunc = () => {}) => {

    const copyActivities = (from, to, form) => {
        copyByTermEvaluationEvent(from, to, form)
        .then(json => {
            if(json.data) {
                alertSuccess("Guardado");
                loadFunc();
            }
            else alertError("Ocurrió un error");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { copyActivities };
}