import React from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import TableCalification from "./table";
import { term_percents, terms_total_points } from "../../../../../_config/term_percents";
import { editProcedureEvent } from "../../../../../_events/procedure/create.event";
import { alertError, alertSuccess } from "../../../../../_utils/alerts";
import { StatusInteractive } from "./status_interactive";

function ActivitiesTable({ hook, term }){

    const { data } = hook;

    let teors = [];
    let pracs = [];
    const totalTerm = React.useRef(0);
    const totalTeor = React.useRef(0);
    const totalPrac = React.useRef(0);
    const totalTermPoints = React.useRef(0);

    const changeValue = (ap, ac, cg, competency, keyValue, value) => {
        let newData = [...hook.data];
        const indexAP = newData.findIndex(a => a.cod === ap);
        if(indexAP !== -1){
            const indexAC = newData[indexAP].concepts.findIndex(a => a.uuid === ac);
            if(indexAC !== -1){
                const indexCG = newData[indexAP].concepts[indexAC].groups.findIndex(a => a.uuid === cg);
                if(indexCG !== -1){
                    const indexC = newData[indexAP].concepts[indexAC].groups[indexCG].competencies.findIndex(a => a.uuid === competency);
                    if(indexC !== -1){
                        if(newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].procedures.length > 0){
                            newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].procedures[0][`procedures_t${keyValue}`] = value;
                        }
                    }
                }
            }
        }
        hook.setData(newData);
    }

    const updateProcedures = (ap, ac, cg, competency) => {
        let newData = [...hook.data];
        const indexAP = newData.findIndex(a => a.cod === ap);
        if(indexAP !== -1){
            const indexAC = newData[indexAP].concepts.findIndex(a => a.uuid === ac);
            if(indexAC !== -1){
                const indexCG = newData[indexAP].concepts[indexAC].groups.findIndex(a => a.uuid === cg);
                if(indexCG !== -1){
                    const indexC = newData[indexAP].concepts[indexAC].groups[indexCG].competencies.findIndex(a => a.uuid === competency);
                    if(indexC !== -1){
                        if(newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].procedures.length > 0){
                            const procedure = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].procedures[0];
                            editProcedureEvent(procedure.uuid, procedure)
                            .then(json => {
                                alertSuccess('Actualizado');
                            })
                            .catch(err => {
                                alertError(err.message);
                            });
                        }
                    }
                }
            }
        }
    };

    const addTotalCompetency = (compUuid, arr, total) => {
        let index = arr.findIndex( c => c.uuid === compUuid );
        if(index !== -1){
            arr[index].total = total;
        }
    };

    return(
        <Container fluid>
            <Row>
                <Col lg={12}>
                {
                    data.map((ap, key) => {
                        let totalAP = 0;
                        let totalPoints = 0;
                        return(
                            <div key={`ap_${key}`}>
                                <div style={{ fontWeight: 'bold', fontSize: '0.8em' }} >
                                    - {ap.name}                                        
                                </div>
                                {
                                    ap.concepts.map((c, key) => {
                                        const color_id = c.color_id ?? '#ffffff';
                                        let totalCompetency = 0;
                                        let conceptObj = {
                                            uuid: c.uuid,
                                            name: c.name,
                                            points: c.total_points,
                                            total: 0
                                        };
                                        if(ap.cod === 'TEOR'){
                                            teors.push(conceptObj);
                                        } else if(ap.cod === 'PRAC'){
                                            pracs.push(conceptObj);
                                        }
                                        return(
                                            <div key={`c_${key}`}>
                                                <div style={{ width: '100%', height: '2px', backgroundColor: color_id }}></div>
                                                <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                    - {c.name}                                    
                                                </div>
                                                {
                                                    c.groups.map((g, key) => {
                                                        return(
                                                            <div key={`g_${key}`}>
                                                                <div style={{ marginLeft: '40px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                                    - {g.name}                                  
                                                                </div>
                                                                {
                                                                    g.competencies.map((co, key) => {  
                                                                        let proceduresCalculated = 0;
                                                                        let proceduresCalculated1 = 0;
                                                                        let proceduresCalculated2 = 0;                                                                      
                                                                        let proceduresCalculated3 = 0;                                                                        
                                                                        for (let index = 0; index < co.activities.length; index++) {
                                                                            let total = 0;
                                                                            const activity = co.activities[index];
                                                                            const evaluations = activity.forms[0]?.evaluations;
                                                                            const maxPoints = Number(activity.max_point) * Number(activity.evaluations_number);
                                                                            totalPoints += maxPoints;
                                                                            totalTermPoints.current += maxPoints;
                                                                            total += evaluations[0] ? Number(evaluations[0].value) : 0;                                                                            
                                                                            total += evaluations[0] ? Number(evaluations[0].value2) : 0;                                                                            
                                                                            total += evaluations[0] ? Number(evaluations[0].value3) : 0;                                                                            
                                                                            total += evaluations[0] ? Number(evaluations[0].value4) : 0;
                                                                            total += evaluations[0] ? Number(evaluations[0].value5) : 0;
                                                                            totalCompetency += total;
                                                                            if(ap.cod === 'TEOR'){
                                                                                totalTeor.current += total;
                                                                                addTotalCompetency(c.uuid, teors, totalCompetency);
                                                                            } else if(ap.cod === 'PRAC'){
                                                                                totalPrac.current += total;
                                                                                addTotalCompetency(c.uuid, pracs, totalCompetency);
                                                                            }
                                                                            totalTerm.current += total;
                                                                            totalAP += total;

                                                                            if(co.needs_procedures === 1){
                                                                                if((evaluations[0] ? Number(evaluations[0].value) : 0) !== 0) proceduresCalculated1 += 1;
                                                                                if((evaluations[0] ? Number(evaluations[0].value2) : 0) !== 0) proceduresCalculated2 += 1;
                                                                                if((evaluations[0] ? Number(evaluations[0].value3) : 0) !== 0) proceduresCalculated3 += 1;
                                                                            }
                                                                            
                                                                            
                                                                        } 

                                                                        if(co.needs_procedures === 1){
                                                                            if(proceduresCalculated1 >= co.activities.length) proceduresCalculated += 1;
                                                                            if(proceduresCalculated2 >= co.activities.length) proceduresCalculated += 1;
                                                                            if(proceduresCalculated3 >= co.activities.length) proceduresCalculated += 1;
                                                                        }
                                                                        

                                                                        return(
                                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} key={`comp_${key}`}>
                                                                                <div style={{ width: '5%' }}>
                                                                                    <div style={{ height: '100%', width: '5px', backgroundColor: color_id }}></div>
                                                                                </div>
                                                                                <div style={{ width: '95%' }}>
                                                                                    <div style={{ marginLeft: '0px', fontSize: '0.8em', fontWeight: 'bold' }}>
                                                                                        {co.name}                                 
                                                                                    </div>
                                                                                    {
                                                                                        co.needs_procedures === 1 && co.procedures.length > 0 &&
                                                                                        <div style={{ marginLeft: '0px', fontSize: '0.8em' }}>
                                                                                            <Table responsive striped bordered>
                                                                                                <tbody>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                    <span className="flex-col" style={{ width: '100%', justifyContent: 'end', alignItems: 'end', fontWeight: 'bold' }}>Procedimientos</span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                    <div className="flex-row">
                                                                                                    <input 
                                                                                                        onChange={(e) => {
                                                                                                            changeValue(ap.cod, c.uuid, g.uuid, co.uuid, term, e.target.value);
                                                                                                        }} 
                                                                                                        value={ co.procedures[0][`procedures_t${term}`] ? co.procedures[0][`procedures_t${term}`] : '' }
                                                                                                        placeholder={proceduresCalculated} 
                                                                                                        type="number" 
                                                                                                        style={{ width: '80px', fontSize: '0.8em' }} 
                                                                                                    />  
                                                                                                    <Button variant="success" size="sm"
                                                                                                        style={{ marginLeft: '20px' }}
                                                                                                        onClick={() => {
                                                                                                            updateProcedures(ap.cod, c.uuid, g.uuid, co.uuid);
                                                                                                        }}
                                                                                                    >
                                                                                                        ACTUALIZAR
                                                                                                    </Button>
                                                                                                    </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                </tbody>
                                                                                            </Table>                                                                                                                 
                                                                                        </div>
                                                                                    }
                                                                                    <div style={{ marginLeft: '0px', marginTop: '15px' }} >
                                                                                    <TableCalification
                                                                                        ap={ap.cod}
                                                                                        ac={c.uuid}
                                                                                        cg={g.uuid}
                                                                                        competency={co}
                                                                                        term={term}
                                                                                        hook={hook}
                                                                                        type={ap.cod}
                                                                                    />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <div style={{ marginLeft: '50px' }}>
                                                <Table responsive striped bordered>
                                                    <thead>
                                                        <tr>
                                                        {Array.from({length: 6}).map((t, index) => (
                                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#eee' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#eee' }} key={`th_${index}`}>{index === 5 ? Number(totalCompetency).toFixed(4) : ''}</th>
                                                        ))}
                                                        </tr>
                                                    </thead>
                                                </Table>
                                                </div>
                                                <div style={{ width: '100%', height: '2px', backgroundColor: color_id }}></div>
                                            </div>
                                        );
                                    })
                                }
                                <hr />
                                <Table responsive striped bordered>
                                    <thead>
                                        <tr>
                                        {Array.from({length: 6}).map((t, index) => (
                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#E2FFD6' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#E2FFD6' }} key={`th_${index}`}>{index === 5 ? (totalAP) : ''}{index === 4 ?  ap.name : ''}{index === 0 ? `Total máximo ${totalPoints}` : ''}</th>
                                        ))}
                                        </tr>
                                    </thead>
                                </Table>
                                <hr />
                                <hr />
                            </div>
                            
                        );
                    })
                }
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            {Array.from({length: 6}).map((t, index) => (
                                <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 0 ? `Teoría` : ''}</th>
                            ))}
                        </tr>
                        {
                            teors.map((dataConcept, key) => {
                                return(
                                    <tr key={`tr_${key}`}>
                                        {Array.from({length: 6}).map((t, index) => (
                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px' } : { fontSize: '0.8em', width: '50%' }} key={`th_${index}_${key}`}>{index === 5 ? Number(dataConcept.total).toFixed(4) : '' }{index === 4 ? 'Total' : ''}{index === 0 ? `${dataConcept.name}${ dataConcept.points > 1 ? ` [${dataConcept.points}]` : '' }` : ''}</th>
                                        ))}
                                    </tr>
                                );
                            })
                        }
                        <tr>
                            {Array.from({length: 6}).map((t, index) => (
                                <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#E2FFD6' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#E2FFD6' }} key={`th_${index}`}>{index === 0 ? `Total:` : index === 5 ? Number(totalTeor.current).toFixed(4) : ''}</th>
                            ))}
                        </tr>
                        <tr>
                            {Array.from({length: 6}).map((t, index) => (
                                <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 0 ? `Práctica` : ''}</th>
                            ))}
                        </tr>
                        {
                            pracs.map((dataConcept, key) => {
                                return(
                                    <tr key={`tr_${key}`}>
                                        {Array.from({length: 6}).map((t, index) => (
                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px'} : { fontSize: '0.8em', width: '50%' }} key={`th_${index}_${key}`}>{index === 5 ? Number(dataConcept.total).toFixed(4) : '' }{index === 4 ? 'Total' : ''}{index === 0 ? `${dataConcept.name}${ dataConcept.points > 1 ? ` [${dataConcept.points}]` : '' }` : ''}</th>
                                        ))}
                                    </tr>
                                );
                            })
                        }
                        <tr>
                            {Array.from({length: 6}).map((t, index) => (
                                <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#E2FFD6' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#E2FFD6' }} key={`th_${index}`}>{index === 0 ? `Total:` : index === 5 ? Number(totalPrac.current).toFixed(4) : ''}</th>
                            ))}
                        </tr>
                        <tr>
                            {Array.from({length: 6}).map((t, index) => (
                                <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 0 ? `Teoría + Práctica` : index === 5 ? Number(totalTeor.current + totalPrac.current).toFixed(4) : ''}</th>
                            ))}
                        </tr>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? Number(totalTerm.current).toFixed(4) : '' }{index === 4 ? 'Total' : ''}{index === 0 ? `Total máximo ${terms_total_points[`${term}`]} (${totalTermPoints.current})` : ''}</th>
                        ))}
                        </tr>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? (Number(totalTerm.current * 5 / terms_total_points[`${term}`]).toFixed(1)) : '' }{index === 4 ? 'NOTA' : ''}{index === 0 ? `NOTA máxima 5` : ''}</th>
                        ))}
                        </tr>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? Number(totalTerm.current * 5 / terms_total_points[`${term}`] * (term_percents[`${term}`] / 100)).toFixed(1) : '' }{index === 4 ? `${term_percents[`${term}`]}%` : ''}</th>
                        ))}
                        </tr>
                    </thead>
                </Table>
                </Col>
                <Col>
                    <StatusInteractive calification={totalTerm.current * 5 / totalTermPoints.current} />
                </Col>
            </Row>
        </Container>
    );
}

export default ActivitiesTable;