import { deleteActivityFormTemplateByFormAndActivityEvent } from '../../_events/activity_form_template/delete.event';
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useActivityFormTemplateDelete = () => {

    const deleteAndLoad = (f, a, loadFunc = () => {}) => {
        deleteActivityFormTemplateByFormAndActivityEvent(f, a)
        .then(json => {
            loadFunc();
            alertSuccess("Eliminado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { deleteAndLoad };
}