import React from "react";

export const useModal = () => {

    const [ open, setOpen ] = React.useState(false);

    const hanldeOpen = (callback) => {
        setOpen(true);
        if(callback) callback();
    }

    const hanldeClose = (callback) => {
        setOpen(false);
        if(callback && typeof callback === 'function') callback();
    }

    return { open, hanldeOpen, hanldeClose };
}