export const validateUser = (body) => {
    const { login, password } = body;
    const { name, email, type } = body.personObj;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(email === null || email === '') throw new Error("Correo vacío");
    if(type === null || type === '') throw new Error("Tipo vacío");
    if(login === null || login === '') throw new Error("Usuario vacío");
    if(password === null || password === '') throw new Error("Contraseña vacío");
}

export const transformEntityUser = (entity) => {
    let newEnt = {...entity};
    newEnt.rol = newEnt.rol.cod;
    return newEnt;
}

export const buildEmptyUser = () => {
    return{
        login: '',
        password: '',
        rol: '',
        person: '',
        personObj: {
            name: '',
            email: '',
            type: '',
        }
    };
}