export const validateObservation = (body) => {
    const { text_value, student, term } = body;
    if(text_value === null || text_value === '') throw new Error("Observación vacía");
    if(student === null || student === '') throw new Error("Estudiante vacío");
    if(term === null || term === '') throw new Error("Corte vacío");
}

export const transformEntityObservation = (entity) => {
    let newEnt = {...entity};
    newEnt.student = newEnt.student.uuid;
    newEnt.term = newEnt.term.uuid;
    return newEnt;
}

export const buildEmptyObservation = () => {
    return{
        text_value: '',
        student: null,
        term: null,
    };
}