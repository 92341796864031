import React from "react";
import MainContent from "../../../../../widgets/templates/main_content";
import Info from "./info";

function HistoryFormsPage(){

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <span className="vp-title">Historial de planillas</span>
                </div>
            </div>
            <hr />
            <Info />
        </MainContent>
    );
}

export default HistoryFormsPage;