import { findAllSemesterPaged, findSemesterById, findAllSemester } from "../../_services/semester.service";

export const findAllSemesterPagedEvent = async (page, limit = 8) => {
    return await findAllSemesterPaged(page, limit);
}

export const findSemesterByIdEvent = async (id) => {
    return await findSemesterById(id);
}

export const findAllSemesterEvent = async () => {
    return await findAllSemester();
}