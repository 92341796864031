import { editCompetencyActivity, createCompetencyActivity, changeStateById } from "../../_services/competency_activity.service";
import { validateCompetencyActivity } from "./model";

export const createCompetencyActivityEvent = async (body) => {
    validateCompetencyActivity(body);
    return await createCompetencyActivity(body);
}

export const editCompetencyActivityEvent = async (id, body) => {
    validateCompetencyActivity(body);
    return await editCompetencyActivity(id, body);
}

export const changeStateByIdCompetencyActivityEvent = async (id, state) => {
    return await changeStateById(id, state);
}