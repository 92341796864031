import React from "react";
import { findAllStudentFormByFormPagedEvent } from "../../_events/student_form/find.event";
import { alertError } from "../../_utils/alerts";

export const useStudentFormByFormPaged = (form, page, limit) => {

    const [data, setData] = React.useState([]);

    const loadData = (f, p, l) => {
        findAllStudentFormByFormPagedEvent(f, p, l)
        .then(json => {
            setData(json.paged);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(form, page, limit);
    }, [form, page, limit]);

    return { data, loadData };
}