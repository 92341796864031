import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link, Outlet } from "react-router-dom";
import { paths } from "../../../app_modules";

function FormTemplateModule(){

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <span className="vp-title">Planillas</span>
                </div>
            </div>
            <hr />
            <div className="flex-row" style={{ width: '100%', background: 'linear-gradient(to right, #074C00, #42A341, #B8E2A3)', padding: '10px 15px', marginLeft: '-20px', marginRight: '-20px' }}>
                <Link to={paths.forms_active} style={{ textDecoration: 'none', color: 'white', marginRight: '20px' }}>Activas</Link>
                <Link to={paths.forms_history} style={{ textDecoration: 'none', color: 'white' }}>Historial</Link>
            </div>            
            <Outlet />
        </MainContent>
    );
}

export default FormTemplateModule;