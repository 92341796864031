import { editFormTemplate, createFormTemplate } from "../../_services/form_template.service";
import { validateFormTemplate } from "./model";

export const createFormTemplateEvent = async (body) => {
    validateFormTemplate(body);
    return await createFormTemplate(body);
}

export const editFormTemplateEvent = async (id, body) => {
    validateFormTemplate(body);
    return await editFormTemplate(id, body);
}