import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptyUser, transformEntityUser } from "../../_events/users/model";
import { buildEmptyPerson, transformEntityPerson } from "../../_events/person/model";
import { findUserByIdEvent } from "../../_events/users/find.event";
import { findPersonByIdEvent } from "../../_events/person/find.event";
import { createUserEvent, editUserEvent } from "../../_events/users/create.event";
import { editPersonEvent } from "../../_events/person/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { useNavigate } from "react-router-dom";
import { path_modules } from "../../pages/app_modules";

export const useUserCreateEdit = () => {

    const query = useQuery();
    const request_uuid = query.get("request");
    const uuid = query.get("id");
    const uuidPerson = query.get("person");
    const navigate = useNavigate();

    const [ entity, setEntity ] = React.useState(buildEmptyUser());
    const [ person, setPerson ] = React.useState(buildEmptyPerson());

    const create = () => {
        createUserEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyUser());
            navigate(`${path_modules.end_register.fullPath}?request=${request_uuid}`)
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editUserEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const editPerson = () => {
        editPersonEvent(uuidPerson, person)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findUserByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityUser(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
        if(uuidPerson){
            findPersonByIdEvent(uuidPerson)
            .then(json => {
                setPerson(transformEntityPerson(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid, uuidPerson]);

    return { entity, setEntity, person, setPerson, uuid, uuidPerson, create, edit, editPerson };
}