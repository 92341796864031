import React, { useEffect } from "react";
import { inactiveCreateUserRequestEvent } from "../../_events/create_user_request/create.event";
import { alertError } from "../../_utils/alerts";
import { useQuery } from "../../_utils/url_query_hook";

export const useInactiveCreateUserRequest = () => {

    const query = useQuery();
    const uuid = query.get("request");

    const [loading, setLoading] = React.useState(false);

    const inactiveData = (id) => {
        setLoading(true);
        inactiveCreateUserRequestEvent(id)
        .then(json => {
            setLoading(false);
        })
        .catch(err => {
            alertError(err.message);
            setLoading(false);
        });
    }

    useEffect(() => {
        inactiveData(uuid);
    }, [uuid]);

    return { loading };
}