export const validateActivityFormTemplate = (body) => {
    const { form, activity } = body;
    if(form === null || form === '') throw new Error("Planilla vacío");
    if(activity === null || activity === '') throw new Error("Actividad vacía");

}

export const transformEntityActivityFormTemplate = (entity) => {
    let newEnt = {...entity};
    return newEnt;
}

export const buildEmptyActivityFormTemplate = (form) => {
    return{
        form,
        activity: '',
    };
}