import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import Table from "./table";
import { paths } from "../../../app_modules";
import AdminHeader from "../../../../widgets/templates/admin_header";

function SemesterPage(){

    return(
        <MainContent>
            <AdminHeader 
                title="Semestres" 
                masivPath={paths.semester_form} 
                createPath={paths.semester_form} 
            />
            <hr />
            <div style={{ width: '100%' }}>
                <Table />
            </div>
        </MainContent>
    );
}

export default SemesterPage;