import { editCompetencyGroup, createCompetencyGroup, changeStateById } from "../../_services/competency_group.service";
import { validateCompetencyGroup } from "./model";

export const createCompetencyGroupEvent = async (body) => {
    validateCompetencyGroup(body);
    return await createCompetencyGroup(body);
}

export const editCompetencyGroupEvent = async (id, body) => {
    validateCompetencyGroup(body);
    return await editCompetencyGroup(id, body);
}

export const changeStateByIdCompetencyGroupEvent = async (id, state) => {
    return await changeStateById(id, state);
}