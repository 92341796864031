import { loginService } from "../../_services/login.service";
import { putAccess, putActions, putRol, putToken, putUserId } from "../../_utils/storage_handler";

export const loginEvent = async (body) => {
    const { login, password } = body;
    let data = null;
    if(login === null || login === '') throw new Error(`Usuario vacío`);
    else if(password === null || password === '') throw new Error(`Contraseña vacía`);
    else {
        const res = await loginService(body);
        data = res.data;
        putUserId(data.uuid);
        putRol(data.rol);
        putToken(data.token);
        putActions(data.actions);
        putAccess(data.access);
    }
    return data;
}