import React from "react";
import { useActivityFormTemplateDelete } from "../../../../_hooks/activity_form_template/useActivityFormTemplateDelete.hook";

function InfoForm({ formId, dataHook, loadFunc }){

    const deleteHook = useActivityFormTemplateDelete();

    /*
    const buildTotal = () => {
        let total = 0;
        for (let index = 0; index < dataHook.data.length; index++) {
            const element = dataHook.data[index];
            for (let index1 = 0; index1 < element.concepts.length; index1++) {
                const element1 = element.concepts[index1];
                for (let index2 = 0; index2 < element1.groups.length; index2++) {
                    const element2 = element1.groups[index2];
                    for (let index3 = 0; index3 < element2.competencies.length; index3++) {
                        const element3 = element2.competencies[index3];
                        for (let index4 = 0; index4 < element3.activities.length; index4++) {
                            let activity = element3.activities[index4];
                            let parts = activity.evaluation_values.split("/").sort((a, b) => Number(b) - Number(a) );
                            total += Number(parts[0]);
                        }
                    }
                }
            }
        }
        return total;
    }*/

    return(
        <div style={{ marginTop: '30px' }}>
            {
                dataHook.data.map((ap, key) => {
                    let total = 0;
                    return(
                        <div key={`ap_${key}`}>
                            <div style={{ fontWeight: 'bold', fontSize: '0.8em' }} >
                                - {ap.name}                                        
                            </div>
                            {
                                ap.concepts.map((c, key) => {
                                    total += c.total_points;
                                    return(
                                        <div key={`c_${key}`}>
                                            <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                - {c.name}  (puntos {c.total_points})                                      
                                            </div>
                                            {
                                                c.groups.map((g, key) => {
                                                    return(
                                                        <div key={`g_${key}`}>
                                                            {
                                                                g.competencies.map((co) => {
                                                                    return(
                                                                        <div key={`co_if${key}`}>
                                                                            <div style={{ marginLeft: '40px', fontSize: '0.8em' }}>
                                                                                - {co.name}                                        
                                                                            </div>
                                                                            {
                                                                                co.activities.map((a, key) => {
                                                                                    return(
                                                                                        <div key={`a_${key}`}>
                                                                                            <div style={{ marginLeft: '80px' }} className="flex-row">
                                                                                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                        deleteHook.deleteAndLoad(formId, a.uuid, () => {
                                                                                                            loadFunc();
                                                                                                        });
                                                                                                    }} >
                                                                                                        <svg width="20px" height="20px" viewBox="-1.6 -1.6 35.20 35.20" version="1.1" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>minus-circle</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Icon-Set-Filled" transform="translate(-518.000000, -1089.000000)" fill="#e01f1f"> <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle"> </path> </g> </g> </g></svg>
                                                                                                </span>
                                                                                                <span style={{ marginLeft: '15px', marginTop: '5px', fontSize: '0.8em' }}>{a.name}</span>                                                                                      
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                            <hr />
                            <div style={{ fontWeight: 'bold', fontSize: '0.8em' }}>Total Puntos {ap.name} {total}</div>
                            <hr />
                            <hr />
                        </div>
                        
                    );
                })
            }
        </div>
    );

}

export default InfoForm;