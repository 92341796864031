import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptyActivityForm, transformEntityActivityForm } from "../../_events/activity_form/model";
import { findActivityFormByIdEvent } from "../../_events/activity_form/find.event";
import { createActivityFormEvent, editActivityFormEvent, addActivityFormActivityEvent } from "../../_events/activity_form/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useActivityFormCreateEdit = (form = '') => {

    const query = useQuery();
    const uuid = query.get("uuid");

    const [ entity, setEntity ] = React.useState(buildEmptyActivityForm(form));

    const create = () => {
        createActivityFormEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyActivityForm(form));
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const createAndLoad = (activity, loadFunc = () => {}) => {
        let newEnt = {...entity};
        newEnt.activity = activity;
        createActivityFormEvent(newEnt)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyActivityForm(form));
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const addActivityToTermAndLoad = (activity, term, loadFunc = () => {}) => {
        let newEnt = {...entity};
        newEnt.activity = activity;
        newEnt.term = term;
        addActivityFormActivityEvent(newEnt)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyActivityForm(form));
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editActivityFormEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findActivityFormByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityActivityForm(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit, createAndLoad, addActivityToTermAndLoad };
}