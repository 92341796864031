import { inactiveFormEvent, activeFormEvent, finishFormEvent } from '../../_events/form/create.event';
import { alertError } from '../../_utils/alerts';

export const useChangeFormState = () => {

    const inactiveAndLoad = (id, loadFunc = () => {}) => {
        inactiveFormEvent(id)
        .then(json => {
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const activeAndLoad = (id, loadFunc = () => {}) => {
        activeFormEvent(id)
        .then(json => {
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const finishAndLoad = (id, loadFunc = () => {}) => {
        finishFormEvent(id)
        .then(json => {
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return {
        inactiveAndLoad,
        activeAndLoad,
        finishAndLoad,
    };
}