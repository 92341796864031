import React from "react";
import { buildEmptyCompetencyDuplication } from "../../_events/competency/model";
import { createByDuplicateCompetencyEvent } from "../../_events/competency/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useCompetencyCreateDuplication = () => {

    const [ entity, setEntity ] = React.useState(buildEmptyCompetencyDuplication());
    const [ loading, setLoading ] = React.useState(false);

    const duplicate = (onSuccess) => {
        setLoading(true);
        createByDuplicateCompetencyEvent(entity)
        .then(json => {
            alertSuccess("Duplicado");
            setEntity(buildEmptyCompetencyDuplication());
            setLoading(false);
            if(onSuccess) onSuccess();
        })
        .catch(err => {
            setLoading(false);
            alertError(err.message);
        });
    }

    return { entity, loading, setEntity, duplicate };
}