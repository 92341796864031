import { editSemester, createSemester } from "../../_services/semester.service";
import { validateSemester } from "./model";

export const createSemesterEvent = async (body) => {
    validateSemester(body);
    return await createSemester(body);
}

export const editSemesterEvent = async (id, body) => {
    validateSemester(body);
    return await editSemester(id, body);
}