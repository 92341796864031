import { findAllPersonPaged, findAllStundentPaged, 
    findPersonById, findAllPerson, findAllTeachers, 
    findAllActiveStundentPaged ,
    findAllStundentNotInActiveFormPaged,
} from "../../_services/person.service";

export const findAllPersonPagedEvent = async (page, limit = 8) => {
    return await findAllPersonPaged(page, limit);
}

export const findAllStundentNotInActiveFormPagedEvent = async (page, limit = 8) => {
    return await findAllStundentNotInActiveFormPaged(page, limit);
}

export const findAllStundentPagedEvent = async (page, limit = 8) => {
    return await findAllStundentPaged(page, limit);
}

export const findAllActiveStundentPagedEvent = async (page, limit = 8) => {
    return await findAllActiveStundentPaged(page, limit);
}

export const findPersonByIdEvent = async (id) => {
    return await findPersonById(id);
}

export const findAllPersonEvent = async () => {
    return await findAllPerson();
}

export const findAllTeachersEvent = async () => {
    return await findAllTeachers();
}