import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/competency-group';

export const findCompetencyGroupById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const changeStateById = async (id, state) => {
    return await handleFetch(`${BASE_PATH}/change-state/${id}/${state}`, GET_OPTIONS);
}

export const findAllCompetencyGroup = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createCompetencyGroup = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editCompetencyGroup = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllCompetencyGroupPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllCompetencyGroupPagedLikeName = async (page, limit, name) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}/name/${name}`, GET_OPTIONS);
}

export const downloadCompetencyGroupProceduresExcelByForm = async (form) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/form/${form}`, GET_OPTIONS);
}

export const downloadCompetencyGroupsProceduresExcelByForms = async (body) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/forms`, PUT_OPTIONS, body);
}