import React from "react";
import { findUserByIdEvent } from "../../_events/users/find.event";
import { alertError } from "../../_utils/alerts";

export const useUser = () => {

    const [data, setData] = React.useState(null);

    const loadData = (id) => {
        findUserByIdEvent(id)
        .then(json => {
            setData(json.data);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { data, loadData };
}