import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS, DELETE_OPTIONS } from './base.service';

const BASE_PATH = '/activity-form-template';

export const findActivityFormTemplateById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const deleteActivityFormTemplateByFormAndActivity = async (form, activity) => {
    return await handleFetch(`${BASE_PATH}/form/activity/${form}/${activity}`, DELETE_OPTIONS);
}

export const findAllActivityFormTemplate = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createActivityFormTemplate = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editActivityFormTemplate = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllActivityFormTemplatePaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}