import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useCompetencyCreateEdit } from "../../../../../_hooks/observation/useObservationByStudentTerm.hook";

function ObservationModal({ studentId, term }){

    const [show, setShow] = React.useState(false);

    const createHook = useCompetencyCreateEdit();

    const handleClose = () => setShow(false);
    const handleShow = () => {
        createHook.load(studentId, term);
        setShow(true);
    }

    return(
        <div>
            <Button onClick={handleShow} variant="success" size="sm">
                Observaciones
            </Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <ModalBody>
                    <div style={{ fontWeight: 'bold' }}>Observaciones corte {term}</div>
                    <hr/>
                    <div>
                    <input 
                    className="vp-input"
                        value={createHook.entity.text_value}
                        onChange={(e) => {
                            let newEnt = {...createHook.entity};
                            newEnt.text_value = e.target.value;
                            createHook.setEntity(newEnt);
                        }} 
                        type="text" style={{ width: '100%', fontSize: '0.8em' }} 
                    />
                    </div>
                    <div className="flex-col flex-center" style={{ width: '100%', marginTop: '20px' }}>
                    <Button
                        style={{ width: '300px' }} 
                        onClick={() => {
                            createHook.edit(createHook.entity.uuid);
                        }} variant="success" size="sm"
                    >
                        Agregar
                    </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default ObservationModal;