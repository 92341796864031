import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { path_modules } from "../../../../app_modules";
import { Link } from "react-router-dom";
import FormTabs from './tabs';

function FormPage(){

    return(
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.operation.children.forms.fullPath}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col lg={12}>
                    <FormTabs />
                </Col>
            </Row>
        </Container>
    );
}

export default FormPage;