import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptySemester, transformEntitySemester } from "../../_events/semester/model";
import { findSemesterByIdEvent } from "../../_events/semester/find.event";
import { createSemesterEvent, editSemesterEvent } from "../../_events/semester/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useSemesterCreateEdit = () => {

    const query = useQuery();
    const uuid = query.get("id");

    const [ entity, setEntity ] = React.useState(buildEmptySemester());

    const create = () => {
        createSemesterEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptySemester());
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editSemesterEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findSemesterByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntitySemester(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit };
}