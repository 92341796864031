import { 
    findAllAcademicPartPaged, 
    findAcademicPartById, 
    findAllAcademicPart, 
    findAllAcademicPartWithFormTemplateBySemester,
    findAllAcademicPartWithFormTemplateNotIncludeBySemester,
    findAllAcademicPartWithFormByIdAndByTerm,
    findAllAcademicPartWithFormNotIncludeBySemester,
    findAllAcademicPartWithFormByStudentFormAndByTerm,
    downloadExcelByStudentForm,
    downloadExcelStudentsByForm,
    findAllAcademicPartWithFormByStudentFormGroupedByTerm,
    findAllAcademicPartWithFormByIdAndByTermWithTotal,
} from "../../_services/academic_parts.services";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const findAllAcademicPartEvent = async () => {
    return await findAllAcademicPart();
}

export const findAllAcademicPartPagedEvent = async (page, limit = 8) => {
    return await findAllAcademicPartPaged(page, limit);
}

export const findAcademicPartByIdEvent = async (id) => {
    return await findAcademicPartById(id);
}

export const findAllAcademicPartWithFormTemplateBySemesterEvent = async (semesser) => {
    return await findAllAcademicPartWithFormTemplateBySemester(semesser);
}

export const findAllAcademicPartWithFormTemplateNotIncludeBySemesterEvent = async (semesser) => {
    return await findAllAcademicPartWithFormTemplateNotIncludeBySemester(semesser);
}

export const findAllAcademicPartWithFormByIdAndByTermEvent = async (id, term) => {
    return await findAllAcademicPartWithFormByIdAndByTerm(id, term);
}

export const findAllAcademicPartWithFormByIdAndByTermWithTotalEvent = async (id, term) => {
    return await findAllAcademicPartWithFormByIdAndByTermWithTotal(id, term);
}

export const findAllAcademicPartWithFormByStudentFormAndByTermEvent = async (sf, term) => {
    return await findAllAcademicPartWithFormByStudentFormAndByTerm(sf, term);
}

export const downloadExcelByStudentFormEvent = async (sf) => {
    return await downloadExcelByStudentForm(sf);
}

export const downloadExcelStudentsByFormEvent = async (form) => {
    const json = await downloadExcelStudentsByForm(form);
    if(json.data.bytes){
        downloadExcelFile(json.data.bytes, `Calificaciones_${new Date().toISOString()}`);
    }
}

export const findAllAcademicPartWithFormByStudentFormGroupedByTermEvent = async (sf) => {
    return await findAllAcademicPartWithFormByStudentFormGroupedByTerm(sf);
}

export const findAllAcademicPartWithFormNotIncludeBySemesterEvent = async (semester, term) => {
    return await findAllAcademicPartWithFormNotIncludeBySemester(semester, term);
}