import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import Table from "./table";
import AdminHeader from "../../../../widgets/templates/admin_header";

function RolesPage(){

    return(
        <MainContent>
            <AdminHeader 
                title="Roles" 
                masivPath={''} 
                createPath={''} 
                actionAuth={'ROL-AD'}
            />
            <hr />
            <div style={{ width: '100%' }}>
                <Table />
            </div>
        </MainContent>
    );
}

export default RolesPage;