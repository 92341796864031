import React from 'react';
import { Result, Button } from 'antd';
import { getToken } from '../_utils/storage_handler';

const AuthWrapper = (AuthComponent) => (
    class extends React.Component{
        render(){
            if(validateSession()){
                return(<AuthComponent {...this.props} />);
            } else {
                return(
                    <Result
                        status="error"
                        title="No estas autorizado"
                        subTitle="Para ingresar debes hacer login"
                        extra={[
                        <Button onClick={() => window.location.href = '/'} type="primary" key="console">
                            Ir a login
                        </Button>
                        ]}
                    />
                );
            }
        }
    }
);

const validateSession = () => {
    let token = getToken();
    if(token == null){
        return false;
    }
    return true;
}

export default AuthWrapper;