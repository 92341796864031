import { editForm, createForm, createFormWithStudents, inactiveForm, activeForm, finishForm, createFormWithStudentsCopy } from "../../_services/form.service";
import { validateForm } from "./model";

export const createFormEvent = async (body) => {
    validateForm(body);
    return await createForm(body);
}

export const createFormWithStudentsEvent = async (body) => {
    validateForm(body);
    return await createFormWithStudents(body);
}

export const createFormWithStudentsCopyEvent = async (body) => {
    validateForm(body);
    return await createFormWithStudentsCopy(body);
}
   

export const editFormEvent = async (id, body) => {
    validateForm(body);
    return await editForm(id, body);
}

export const inactiveFormEvent = async (id) => {
    return await inactiveForm(id);
}

export const activeFormEvent = async (id) => {
    return await activeForm(id);
}

export const finishFormEvent = async (id) => {
    return await finishForm(id);
}