import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/person';

export const findPersonById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllPerson = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createPerson = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editPerson = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllPersonPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllStundentPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/type/ESTU/${page}/${limit}`, GET_OPTIONS);
}

export const findAllStundentNotInActiveFormPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/type-active-not-in-form/ESTU/${page}/${limit}`, GET_OPTIONS);
}

export const findAllActiveStundentPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/type-active/ESTU/${page}/${limit}`, GET_OPTIONS);
}

export const findAllTeachers = async () => {
    return await handleFetch(`${BASE_PATH}/type/DOCE`, GET_OPTIONS);
}

export const inactivePerson = async (id) => {
    return await handleFetch(`${BASE_PATH}/inactive/${id}`, PUT_OPTIONS);
}

export const activePerson = async (id) => {
    return await handleFetch(`${BASE_PATH}/active/${id}`, PUT_OPTIONS);
}