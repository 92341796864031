import { inactivePersonEvent, activePersonEvent } from '../../_events/person/create.event';
import { alertError } from '../../_utils/alerts';

export const useChangePersonState = () => {

    const inactiveAndLoad = (id, loadFunc = () => {}) => {
        inactivePersonEvent(id)
        .then(json => {
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const activeAndLoad = (id, loadFunc = () => {}) => {
        activePersonEvent(id)
        .then(json => {
            loadFunc();
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return {
        inactiveAndLoad,
        activeAndLoad,
    };
}