import React from "react";
import { buildEmptyCreateUserRequest } from "../../_events/create_user_request/model";
import { createCreateUserRequestEvent } from "../../_events/create_user_request/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useCreateCreateUserRequest = () => {

    const [ entity, setEntity ] = React.useState(buildEmptyCreateUserRequest());
    const [ requestLink, setRequestLink ] = React.useState(null);

    const create = () => {
        createCreateUserRequestEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyCreateUserRequest());
            setRequestLink(`https://practicum.com.co/register?request=${json.data?.uuid}`);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { entity, requestLink, setEntity, create };
}