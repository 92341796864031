import { deleteStudentFormByIdEvent } from "../../_events/student_form/delete.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useDeleteStudentForm = () => {

    const remove = (id, reload = () => {}) => {
        deleteStudentFormByIdEvent(id)
        .then(json => {
            reload();
            alertSuccess('Eliminado');
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { remove };
}