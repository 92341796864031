import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AddActivityModal from "./add_activity_modal";
import SelectPosition from "./select_position";
import CopyTermModal from "./copy_term_modal";

function TermsInfo({ form, term, data, total, semester, deleteAndLoad, loadFunc }){

    let position = 0;

    return(
        <Container fluid>
            <Row>
                <Col lg={10}>
                    <strong style={{ fontSize: '0.9em' }}>Corte {term}</strong>
                    <div className="flex-row">
                        <div style={{ margin: '0 10px' }}>
                            <AddActivityModal
                                formId={form}
                                loadFunc={loadFunc}
                                semester={semester}
                                term={term}
                            />
                        </div>
                        <CopyTermModal
                            formId={form}
                            term={term}
                            loadFunc={loadFunc}
                        />
                    </div>
                    <hr />
                    <div style={{ marginTop: '30px' }}>
                    {
                        data.map((ap, key) => {
                            let totalPoints = 0;
                            return(
                                <div key={`ap_${key}`}>
                                    <div style={{ fontWeight: 'bold', fontSize: '0.8em' }} >
                                        - {ap.name}                                        
                                    </div>
                                    {
                                        ap.concepts.map((c, key) => {
                                            totalPoints += c.total_points;
                                            return(
                                                <div key={`c_${key}`}>
                                                    <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                        - {c.name}  (puntos {c.total_points})                                      
                                                    </div>
                                                    {
                                                        c.groups.map((g, key) => {
                                                            return(
                                                                <div key={`g_${key}`}>
                                                                    {
                                                                        g.competencies.map((co, indexG) => {
                                                                            return(
                                                                                <div key={`co_ti_${term}_${indexG}`}>
                                                                                    <div style={{ marginLeft: '40px', fontSize: '0.8em' }}>
                                                                                        - {co.name}                                        
                                                                                    </div>
                                                                                    {
                                                                                        co.activities.map((a, key) => {
                                                                                            position += 1;
                                                                                            return(
                                                                                                <div key={`a_${key}`}>
                                                                                                    <div style={{ marginLeft: '80px' }} className="flex-row">
                                                                                                        <SelectPosition
                                                                                                            total={total}
                                                                                                            position={position}
                                                                                                            activity={a.uuid}
                                                                                                            load={loadFunc}
                                                                                                            form={form}
                                                                                                            term={term}
                                                                                                        />
                                                                                                        <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                deleteAndLoad(form, a.uuid, term);
                                                                                                            }} >
                                                                                                                <svg width="20px" height="20px" viewBox="-1.6 -1.6 35.20 35.20" version="1.1" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>minus-circle</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Icon-Set-Filled" transform="translate(-518.000000, -1089.000000)" fill="#e01f1f"> <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle"> </path> </g> </g> </g></svg>
                                                                                                        </span>
                                                                                                        <span style={{ marginLeft: '15px', marginTop: '5px', fontSize: '0.8em' }}>{a.name}</span>                                                                                      
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                    <hr />
                                    <div style={{ fontWeight: 'bold', fontSize: '0.8em' }}>Total Puntos {ap.name} {totalPoints}</div>
                                    <hr />
                                    <hr />
                                </div>
                                
                            );
                        })
                    }
                </div>                 
                </Col>
            </Row>
        </Container>
    );
}

export default TermsInfo;