import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/action-rol';

export const findActionRolById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllActionRolByRol = async (rol) => {
    return await handleFetch(`${BASE_PATH}/all/rol/${rol}`, GET_OPTIONS);
}

export const findAllActionRol = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createActionRol = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const updateActionRol = async (body) => {
    return await handleFetch(`${BASE_PATH}/update`, POST_OPTIONS, body);
}

export const editActionRol = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllActionRolPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}