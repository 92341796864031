import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ActionWrapper from "../../hoc/access_wrapper";

function AdminHeader({ title, createPath, masivPath, actionAuth }){

    const buildActionWrapper = () => {
        let ActionLink = ActionWrapper(Link, actionAuth);
        return(
            <ActionLink to={createPath} style={{ width: '90%', marginLeft: '8px' }}>
                <Button variant='success' size="sm" style={{ width: '100%' }}>
                    Crear nuevo
                </Button>
            </ActionLink>
        );
    }

    return(
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <div style={{ width: '60%', padding: '15px 0' }}>
                        <span className="vp-title">{title}</span>
                    </div>
                </Col>
                <Col lg={6}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', padding: '15px 0' }}>
                        {
                            ! actionAuth ?
                                <Link to={createPath} style={{ width: '90%', marginLeft: '8px' }}>
                                    <Button variant='success' size="sm" style={{ width: '100%' }}>
                                        Crear nuevo
                                    </Button>
                                </Link>
                            :
                                buildActionWrapper()
                        }
                        
                        {/*
                        <Link to={masivPath} style={{ width: '90%' }}>
                            <Button variant='success' size="sm" style={{ width: '100%' }}>
                                Cargue masivo
                            </Button>
                        </Link>
                        */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminHeader;