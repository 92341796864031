import React from "react";
import { findAllCompetencyGroupPagedEvent, findAllCompetencyGroupPagedLikeNameEvent } from "../../_events/competency-group/find.event";
import { alertError } from "../../_utils/alerts";

export const useCompetencyGroupPaged = ({ page = 0, limit = 8 }) => {

    const [data, setData] = React.useState([]);

    const loadData = (p, l, filterName) => {
        if(filterName === '' || filterName === undefined || filterName === null){
            findAllCompetencyGroupPagedEvent(p, l)
            .then(json => {
                setData(json.paged);
            })
            .catch(err => {
                alertError(err.message);
            });
        } else {
            findAllCompetencyGroupPagedLikeNameEvent(p, l, filterName)
            .then(json => {
                setData(json.paged);
            })
            .catch(err => {
                alertError(err.message);
            });
        }
    }

    React.useEffect(() => {
        loadData(page, limit, '');
    }, [page, limit]);

    return { data, loadData };
}