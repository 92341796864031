import React from "react";
import PagedTable from "../../../../../widgets/tables/paged_table";
import { useStudentNotInActiveFormPaged } from "../../../../../_hooks/person/useStudentNotInActiveFormPaged.hook";

function StudentsTable({ students, selectStudent = (checked, uuid) => {} }){

    const COUNT = 10;
    const academicConceptPaged = useStudentNotInActiveFormPaged(0, COUNT);
    const [ currentPage, setCurrentPage ] = React.useState(0);

    const columns = [
        {
            key: 'uuid',
            name: '',
            label: false,
            render: (param) => {
                return(
                    <input
                        type="checkbox"
                        checked={students.findIndex(s => s === param.uuid) !== -1 ? true : false}
                        onChange={(e) => {
                            selectStudent(e.target.checked, param.uuid);
                        }}
                    />
                );
            }
        },
        {
            key: 'name',
            name: 'Nombre',
            label: true,
        },
        {
            key: 'email',
            name: 'Correo',
            label: true,
        },
    ];

    const changePage = (page) => {
        setCurrentPage(page);
        academicConceptPaged.loadData(page, COUNT);
    }

    return(
        <PagedTable
            columns={columns}
            data={academicConceptPaged.data[0]}
            total={academicConceptPaged.data[1]}
            dataPerPage={COUNT}
            currentPage={currentPage}
            onChangePage={(page) => changePage(page)}
        />
    );
}

export default StudentsTable;