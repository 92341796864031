import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link } from "react-router-dom";
import { path_modules } from "../../../app_modules";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useUserCreateEdit } from "../../../../_hooks/user/useUserCreateEdit.hook";
import { useTypePersonAll } from "../../../../_hooks/type_person/useAllTypePErson.hook";
import ObjectSelect from "../../../../widgets/select/object_select";
import { useAllRoles } from "../../../../_hooks/roles/useAllRoles.hook";
import { useEditPassword } from "../../../../_hooks/user/useEditPassword.hook";

function UserForm(){

    const creationHook = useUserCreateEdit();
    const { uuid, uuidPerson } = creationHook;
    const changePasswordHook = useEditPassword(uuid);

    const allTypesHook = useTypePersonAll();
    const allRolesHook = useAllRoles();

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.admin.children.users.fullPath}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                    <span className="vp-title">Usuario</span>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='form-label'>Nombre completo</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ uuidPerson ? creationHook.person?.name : creationHook.entity?.personObj?.name }
                                onChange={(e) => {
                                    if(uuidPerson){
                                        let newEnt = {...creationHook.person};
                                        newEnt.name = e.target.value;
                                        creationHook.setPerson(newEnt);
                                    } else {
                                        let newEnt = {...creationHook.entity};
                                        newEnt.personObj.name = e.target.value;
                                        creationHook.setEntity(newEnt);
                                    }                                    
                                }}
                            />
                            <div className='form-label'>Correo</div>
                            <input 
                                className='vp-input' 
                                type='email'
                                value={ uuidPerson ? creationHook.person?.email : creationHook.entity?.personObj?.email }
                                onChange={(e) => {
                                    if(uuidPerson){
                                        let newEnt = {...creationHook.person};
                                        newEnt.email = e.target.value;
                                        creationHook.setPerson(newEnt);
                                    } else {
                                        let newEnt = {...creationHook.entity};
                                        newEnt.personObj.email = e.target.value;
                                        creationHook.setEntity(newEnt);
                                    }                                    
                                }}
                            />
                            <div className='form-label'>Tipo</div>
                            <ObjectSelect
                                value={ uuidPerson ? creationHook.person?.type : creationHook.entity?.personObj?.type }
                                options={allTypesHook.data.map( d => ({ value: d.cod, label: d.name }))}
                                onChange={(e) => {
                                    if(uuidPerson){
                                        let newEnt = {...creationHook.person};
                                        newEnt.type = e;
                                        creationHook.setPerson(newEnt);
                                    } else {
                                        let newEnt = {...creationHook.entity};
                                        newEnt.personObj.type = e;
                                        creationHook.setEntity(newEnt);
                                    }                                    
                                }}
                            />
                            {
                                uuidPerson && 
                                <Button onClick={ () => creationHook.editPerson() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                    Editar
                                </Button>
                            }
                        </Col>
                        <Col lg={6}>
                        <div className='form-label'>Usuario</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity.login }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.login = e.target.value;
                                    creationHook.setEntity(newEnt);                             
                                }}
                            />
                            {
                                !uuid &&
                                <div>
                                    <div className='form-label'>Contraseña</div>
                                    <input 
                                        className='vp-input' 
                                        type='password'
                                        value={ creationHook.entity.password }
                                        onChange={(e) => {
                                            let newEnt = {...creationHook.entity};
                                            newEnt.password = e.target.value;
                                            creationHook.setEntity(newEnt);                             
                                        }}
                                    />
                                </div>
                            }
                            <div className='form-label'>Rol</div>
                            <ObjectSelect
                                value={ creationHook.entity.rol }
                                options={allRolesHook.data.map( d => ({ value: d.cod, label: d.name }))}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.rol = e;
                                    creationHook.setEntity(newEnt);                                
                                }}
                            />
                            <Button onClick={ uuid ? () => creationHook.edit() : () => creationHook.create() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                { uuid ? 'Editar' : 'Crear' }
                            </Button>
                            {
                                uuid &&
                                <div>
                                    <hr />
                                    <div className='form-label'>Cambiar contraseña</div>
                                    <input 
                                        className='vp-input' 
                                        type='password'
                                        value={ changePasswordHook.entity.password }
                                        onChange={(e) => {
                                            let newEnt = {...changePasswordHook.entity};
                                            newEnt.password = e.target.value;
                                            changePasswordHook.setEntity(newEnt);                             
                                        }}
                                    />
                                    <Button onClick={ () => changePasswordHook.edit() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                        Cambiar contraseña
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </MainContent>
    );
}

export default UserForm;