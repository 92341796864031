import { editObservation, createObservation } from "../../_services/observation.service";
import { validateObservation } from "./model";

export const createObservationEvent = async (body) => {
    validateObservation(body);
    return await createObservation(body);
}

export const editObservationEvent = async (id, body) => {
    validateObservation(body);
    return await editObservation(id, body);
}