import { 
    findActiveFormBySemester, 
    findFormById, 
    findAllFormPaged, 
    findAllForm, 
    findFormByIdWithAll, 
    findFinishFormBySemester,
    findAllActiveFormNotId 
} from "../../_services/form.service";

export const findActiveFormBySemesterEvent = async (id) => {
    return await findActiveFormBySemester(id);
}

export const findFinishFormBySemesterEvent = async (id) => {
    return await findFinishFormBySemester(id);
}

export const findAllFormPagedEvent = async (page = 0, limit = 8) => {
    return await findAllFormPaged(page, limit);
}

export const findFormByIdEvent = async (id) => {
    return await findFormById(id);
}

export const findFormByIdWithAllEvent = async (id) => {
    return await findFormByIdWithAll(id);
}

export const findAllFormEvent = async () => {
    return await findAllForm();
}

export const findAllActiveFormNotIdEvent = async (id) => {
    return await findAllActiveFormNotId(id);
}