import React from "react";
import { findAllAcademicConceptPagedEvent, findAllAcademicConceptPagedLikeNameEvent } from "../../_events/academic_concept/find.event";
import { alertError } from "../../_utils/alerts";

export const useAcademicConceptPaged = ({ page = 0, limit = 8 }) => {

    const [data, setData] = React.useState([]);

    const loadData = (p, l, filterName) => {
        if(filterName === '' || filterName === undefined || filterName === ''){
            findAllAcademicConceptPagedEvent(p, l)
            .then(json => {
                setData(json.paged);
            })
            .catch(err => {
                alertError(err.message);
            });
        } else {
            findAllAcademicConceptPagedLikeNameEvent(p, l, filterName)
            .then(json => {
                setData(json.paged);
            })
            .catch(err => {
                alertError(err.message);
            });
        }
        
    }

    React.useEffect(() => {
        loadData(page, limit, '');
    }, [page, limit]);

    return { data, loadData };
}