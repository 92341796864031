const MAX_EVALUATION = 5;

export const evalToPoint = (evaluation, maxPoint) => {
    return evaluation * maxPoint / MAX_EVALUATION;
}

export const pointToEval = (point, maxPoint) => {
    return point * MAX_EVALUATION / maxPoint;
}

export const additionalEvalToPoint = (evaluation, maxPoint, percent) => {
    const evaluationN = Number(evaluation);
    const percentN = Number(percent);
    const maxPointN = (maxPoint);
    return (evaluationN * maxPointN / MAX_EVALUATION) * (percentN / 100);
}

export const pointToAdditionalEval = (point, maxPoint, percent) => {
    const pointN = Number(point);
    const percentN = Number(percent);
    const maxPointN = (maxPoint);
    return (pointN * 100 / percentN) * MAX_EVALUATION / maxPointN;
}