export const validateForm = (body) => {
    const { semester, person, personEndo, personRehab, students } = body;
    if(semester === null || semester === '') throw new Error("Semestre vacío");
    if(person === null || person === '') throw new Error("Tutor 1 vacío");
    if(personEndo === null || personEndo === '') throw new Error("Tutor 3 vacío");
    if(personRehab === null || personRehab === '') throw new Error("Tutor 2 vacío");
    if(students){
        if(students.length === 0) throw new Error("Sin estudiantes");
    }
}

export const transformEntityForm = (entity) => {
    let newEnt = {...entity};
    newEnt.person = newEnt.person?.uuid;
    newEnt.personEndo = newEnt.personEndo?.uuid;
    newEnt.personRehab = newEnt.personRehab?.uuid;
    return newEnt;
}

export const buildEmptyForm = (semester) => {
    return{
        person: '',
        personEndo: '',
        personRehab: '',
        semester,
        students: [],
        copy_form: '',
        copy_name: '',
    };
}