import React from "react";
import { Button } from "react-bootstrap";

function SemestersMenu({ hook }){

    return(
        <div style={{ width: '100%' }}>
        {
            hook.semesters.map((s, key) => {
                return(
                    <Button
                        style={{ width: '100%' }}
                        key = {`btn_${key}`}
                        size="sm"
                        variant={ hook.selected === s.cod ? "success" : "gray"}
                        onClick={() => {
                            hook.setSelected(s.cod)
                            hook.loadData(s.cod)
                        }}
                    >
                        {s.name} semestre
                    </Button>
                );
            })
        }
        </div>
    );
}

export default SemestersMenu;