import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { findAllActionRolByRol, updateActionRol } from "../../../../_services/action_rol.service";
import { alertError, alertSuccess } from "../../../../_utils/alerts";
import { findAllAction } from "../../../../_services/action.service";

export default function ActionsModal({ rol }) {
    const [show, setShow] = useState(false);
    const [ actionList, setActionList ] = useState([]);
    const [ actions, setActions ] = useState([]);
  
    const handleClose = () => setShow(false);
    const handleShow = () => {
        findAllActionRolByRol(rol)
        .then(json => {
            setActions(json.list);
            findAllAction()
            .then(json => {
                setActionList(json.list);
            })
            .catch(err => {
                alertError(err.message);
            });
        })
        .catch(err => {
            alertError(err.message);
        });
        setShow(true);
    };

    const update = () => {
        updateActionRol({
            rol: rol,
            actions: actions,
        })
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const changeActions = (checked, actionCod) => {
        let newAccess = [...actions];
        if(checked){
            newAccess.push(actionCod);
        } else {
            let index = newAccess.indexOf(actionCod);
            if(index !== -1) newAccess.splice(index, 1);
        }
        setActions(newAccess);
    }
  
    return (
        <>
            <Button style={{ margin: '0 5px' }} variant="warning" size="sm" onClick={handleShow}>
                Permisos
            </Button>
    
            <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Acciones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    actionList.map( (ac, key) => (
                        <div key={`check_action_${key}`} className="flex-row" >
                            <input
                                style={{ marginRight: '10px' }}
                                id={`check_${key}`} 
                                type="checkbox"
                                checked = { actions.some( a => a === ac.cod ) }
                                onChange={(e) => {
                                    changeActions(e.target.checked, ac.cod);
                                }}
                            />
                            <label style={{ fontSize: '0.95em' }} htmlFor={`check_${key}`}>{ac.name}</label>
                        </div>
                    ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="success" onClick={update}>
                    Actualizar
                </Button>
            </Modal.Footer>
            </Modal>
        </>
    );
}