import React from "react";
import { Button, Modal } from "react-bootstrap";
import { findAllFormPagedEvent } from "../../../../../_events/form/find.event";
import PagedTable from "../../../../../widgets/tables/paged_table";
import { useFormPaged } from "../../../../../_hooks/form/useFormPaged.hook";

export default function FormsModal({ hook }){

    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        findAllFormPagedEvent()
        setShow(true);
    };

    return(
        <>
            <Button onClick={handleShow} size="sm" variant="primary">
                Planillas para copiar
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table hook={hook} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function Table({ hook }){

    const COUNT = 10;
    const formHook = useFormPaged( 0, COUNT );
    const [ currentPage, setCurrentPage ] = React.useState(0);

    const columns = [
        {
            key: 'date_created',
            name: 'Fecha',
            label: false,
            render: (param) => (
                <span>{param.date_created.split("T")[0]}</span>
            )
        },
        {
            key: 'semester',
            name: 'Semestre',
            label: false,
            render: (param) => (
                <span>{param.semester?.name}</span>
            )
        },
        {
            key: '_',
            name: 'Acción',
            label: false,
            render: (param) => {
                return(
                    <Button onClick={() => {
                        let ent = { ...hook.entity };
                        ent.copy_form = param.uuid;
                        ent.copy_name = `${param?.semester?.name} ${param.date_created?.split("T")[0]}`;
                        hook.setEntity(ent);
                    }} variant="success" size="sm">Seleccionar</Button>
                );
            }
        },
    ];

    const changePage = (page) => {
        setCurrentPage(page);
        formHook.loadData(page, COUNT);
    }

    return(
        <PagedTable
            columns={columns}
            data={formHook.data[0]}
            total={formHook.data[1]}
            dataPerPage={COUNT}
            currentPage={currentPage}
            onChangePage={(page) => changePage(page)}
        />
    );
}