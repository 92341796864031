import React from "react";
import { Button, Table } from "react-bootstrap";
import { useEvaluate } from "../../../../../_hooks/evaluation/useEvaluate.hook";
import { evalToPoint, pointToEval, additionalEvalToPoint, pointToAdditionalEval } from "../../../../../_utils/evaluation_transforms";
import UpdatedByModal from "./updated-by-modal";

function TableCalification({ ap, ac, cg, competency, term, hook, type }){

    const titles = ['Actividades', 'Calificación 1', 'Calificación 2', 'Calificación 3', 'Adicional 1', 'Adicional 2', 'ACTUALIZAR', 'TOTAL'];

    const evaluateHook = useEvaluate();

    const buildEvaluations = (competencyId, uuid, activity) => {
        let evaluations = [];
        for (let index = 1; index <= 3; index++) {
            let value = document.getElementById(`${competencyId}_c${index}`)?.value;
            if(value !== null && value !== undefined && value !== '') evaluations.push(value !== '0' ? evalToPoint(Number(value), activity.max_point) : value);
            else evaluations.push(null);
        }
        for (let index = 4; index <= 5; index++) {
            let value = document.getElementById(`${competencyId}_c${index}`)?.value;
            const maxPoint = activity.max_point;
            const additionalPercent = activity.additional_percent ?? 20;
            if(value !== null && value !== undefined && value !== '') evaluations.push(value !== '0' ? additionalEvalToPoint(Number(value), maxPoint, additionalPercent) : value);
            else evaluations.push(null);
        }
        for (let index = 1; index <= 5; index++) {
            let value = document.getElementById(`${competencyId}_pacient_c${index}`)?.value;
            if(value !== null && value !== undefined && value !== '') evaluations.push(value);
            else evaluations.push(null);
        }
        return {
            uuid,
            value: evaluations[0],
            value2: evaluations[1],
            value3: evaluations[2],
            value4: evaluations[3],
            value5: evaluations[4],
            pacient_info: evaluations[5],
            pacient_info2: evaluations[6],
            pacient_info3: evaluations[7],
            pacient_info4: evaluations[8],
            pacient_info5: evaluations[9],
        };
    }

    const changeValue = (activity, keyValue, value, order_value) => {
        let newData = [...hook.data];
        const indexAP = newData.findIndex(a => a.cod === ap);
        if(indexAP !== -1){
            const indexAC = newData[indexAP].concepts.findIndex(a => a.uuid === ac);
            if(indexAC !== -1){
                const indexCG = newData[indexAP].concepts[indexAC].groups.findIndex(a => a.uuid === cg);
                if(indexCG !== -1){
                    const indexC = newData[indexAP].concepts[indexAC].groups[indexCG].competencies.findIndex(a => a.uuid === competency.uuid);
                    if(indexC !== -1){
                        const indexA = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities.findIndex(a => a.uuid === activity);
                        if(indexA !==-1){
                            //newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = value;
                            
                            if(! isNaN(value) && value !== ''){
                                if(Number(value) <= 5){                                   
                                    const maxPoint = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].max_point;
                                    newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = evalToPoint(Number(value), maxPoint).toString();
                                }
                            } else {
                                newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = value;
                            }                                                                        
                        }
                    }
                }
            }
        }
        hook.setData(newData);
    }

    const changeValuePlain = (activity, keyValue, value, order_value) => {
        let newData = [...hook.data];
        const indexAP = newData.findIndex(a => a.cod === ap);
        if(indexAP !== -1){
            const indexAC = newData[indexAP].concepts.findIndex(a => a.uuid === ac);
            if(indexAC !== -1){
                const indexCG = newData[indexAP].concepts[indexAC].groups.findIndex(a => a.uuid === cg);
                if(indexCG !== -1){
                    const indexC = newData[indexAP].concepts[indexAC].groups[indexCG].competencies.findIndex(a => a.uuid === competency.uuid);
                    if(indexC !== -1){
                        const indexA = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities.findIndex(a => a.uuid === activity);
                        if(indexA !==-1){
                            newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = value;
                                                                                                   
                        }
                    }
                }
            }
        }
        hook.setData(newData);
    }

    const changeAdditionalValue = (activity, keyValue, value, order_value) => {
        let newData = [...hook.data];
        const indexAP = newData.findIndex(a => a.cod === ap);
        if(indexAP !== -1){
            const indexAC = newData[indexAP].concepts.findIndex(a => a.uuid === ac);
            if(indexAC !== -1){
                const indexCG = newData[indexAP].concepts[indexAC].groups.findIndex(a => a.uuid === cg);
                if(indexCG !== -1){
                    const indexC = newData[indexAP].concepts[indexAC].groups[indexCG].competencies.findIndex(a => a.uuid === competency.uuid);
                    if(indexC !== -1){
                        const indexA = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities.findIndex(a => a.uuid === activity);
                        if(indexA !==-1){
                            
                            if(! isNaN(value) && value !== ''){
                                if(Number(value) <= 5){                                   
                                    const maxPoint = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].max_point;
                                    const additionalPercent = newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].additional_percent ?? 20;
                                    newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = additionalEvalToPoint(Number(value), maxPoint, additionalPercent).toString();
                                }
                            } else {
                                newData[indexAP].concepts[indexAC].groups[indexCG].competencies[indexC].activities[indexA].forms[0].evaluations[0][`${keyValue}`] = value;
                            }     
                        }
                    }
                }
            }
        }
        hook.setData(newData);
    }

    const showValue = (evaluation, activity) => {
        return evaluation ? pointToEval(Number(evaluation), activity.max_point).toString() : '';
    }

    const showAdditionalValue = (evaluation, activity) => {
        return evaluation ? pointToAdditionalEval(Number(evaluation), activity.max_point, activity.additional_percent).toString() : '';
    }

    return(
        <Table responsive striped bordered>
            <thead>
                <tr>
                {titles.map((t, index) => (
                    <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px' } : { fontSize: '0.8em', width: '50%' }} key={`th_${index}`}>{t}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {
                    competency.activities &&
                    competency.activities.map((activity, key) => {
                        let total = 0;                        
                        const values = [5, 4, 3, 2, 1, 0];                        
                        const activityFormId = activity.forms[0]?.uuid;
                        const evaluations = activity.forms[0]?.evaluations;
                        const evaluationUpdatedBy = evaluations[0].updated_by;
                        total += evaluations[0] ? Number(evaluations[0].value) : 0;
                        total += evaluations[0] ? Number(evaluations[0].value2) : 0;
                        total += evaluations[0] ? Number(evaluations[0].value3) : 0;
                        total += evaluations[0] ? Number(evaluations[0].value4) : 0;
                        total += evaluations[0] ? Number(evaluations[0].value5) : 0;
                        return(
                            <tr key={`tr_${key}`}>
                                <td style={{ fontSize: '0.7em' }}>{activity.name} <strong>[{activity.max_point}]</strong></td>
                                <td style={{ fontSize: '0.8em' }}>
                                    <div className="flex-col">
                                        {
                                            type === 'PRAC' && key === 0 && 
                                            <input
                                            id={`${activityFormId}_pacient_c1`}
                                            value={ evaluations[0] ? evaluations[0].pacient_info ? evaluations[0].pacient_info : '' : '' }
                                            onChange={(e) => {
                                                changeValuePlain(activity.uuid, 'pacient_info', e.target.value, 1);                                         
                                            }} 
                                            placeholder="Paciente" type="text" style={{ width: '70px', fontSize: '0.8em', marginBottom: '7px' }} />
                                        }
                                    {
                                        activity.evaluations_number > 0 ?
                                        activity.select_evaluation === 1 ?
                                            <select id={`${activityFormId}_c1`} style={{ width: '70px', fontSize: '0.8em' }} 
                                                onChange={(e) => {
                                                    changeValue(activity.uuid, 'value', e.target.value.toString(), 1);
                                                }}                                               
                                                value={ showValue(evaluations[0] ? evaluations[0].value : '', activity) }>
                                                <option value={''}>
                                                    Sin dato
                                                </option>
                                                {
                                                    values.map((v, key) => {
                                                        return(
                                                            <option value={v} key={`s1_${key}`}>
                                                                {v}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        :
                                        <input 
                                            id={`${activityFormId}_c1`}
                                            value={ showValue(evaluations[0] ? evaluations[0].value : '', activity) }
                                            onChange={(e) => {
                                                if(Number(e.target.value) > 5){

                                                } else {
                                                    changeValue(activity.uuid, 'value', e.target.value, 1);
                                                }
                                                
                                            }} 
                                            placeholder="Sin dato" type="number" style={{ width: '70px', fontSize: '0.8em' }} />
                                        :
                                        <div>N.A.</div>
                                    }
                                    </div>
                                </td>
                                <td style={{ fontSize: '0.8em' }}>
                                    <div className="flex-col">
                                        {
                                            type === 'PRAC' && key === 0 && activity.evaluations_number > 1 &&
                                            <input
                                            id={`${activityFormId}_pacient_c2`}
                                            value={ evaluations[0] ? evaluations[0].pacient_info2 ? evaluations[0].pacient_info2 : '' : '' }
                                            onChange={(e) => {
                                                changeValuePlain(activity.uuid, 'pacient_info2', e.target.value, 2);                                            
                                            }} 
                                            placeholder="Paciente" type="text" style={{ width: '70px', fontSize: '0.8em', marginBottom: '7px' }} />
                                        }
                                    {
                                        activity.evaluations_number > 1 ?
                                        activity.select_evaluation ?
                                        <select id={`${activityFormId}_c2`} style={{ width: '70px',  fontSize: '0.8em' }}
                                            onChange={(e) => {                                                
                                                changeValue(activity.uuid, 'value2', e.target.value.toString(), 2);
                                            }} 
                                            value={ showValue(evaluations[0] ? evaluations[0].value2 : '', activity) }>
                                            <option value={''}>
                                                Sin dato
                                            </option>
                                            {
                                                values.map((v, key) => {
                                                    return(
                                                        <option value={v} key={`s2_${key}`}>
                                                            {v}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                        :
                                        <input 
                                            onChange={(e) => {
                                                changeValue(activity.uuid, 'value2', e.target.value, 2);
                                            }} 
                                            value={ showValue(evaluations[0] ? evaluations[0].value2 : '', activity) }
                                            id={`${activityFormId}_c2`}
                                            placeholder="Sin dato" 
                                            type="number" 
                                            style={{ width: '70px', fontSize: '0.8em' }} />
                                        :
                                            <div>N.A.</div>
                                    }
                                    </div>
                                </td>
                                <td style={{ fontSize: '0.8em' }}>
                                    <div className="flex-col">
                                        {
                                            type === 'PRAC' && key === 0 && activity.evaluations_number > 2 && 
                                            <input
                                            id={`${activityFormId}_pacient_c3`}
                                            value={ evaluations[0] ? evaluations[0].pacient_info3 ? evaluations[0].pacient_info3 : '' : '' }
                                            onChange={(e) => {
                                                changeValuePlain(activity.uuid, 'pacient_info3', e.target.value, 3);                                          
                                            }} 
                                            placeholder="Paciente" type="text" style={{ width: '70px', fontSize: '0.8em', marginBottom: '7px' }} />
                                        }
                                    {
                                        activity.evaluations_number > 2 ?
                                        activity.select_evaluation ?
                                        <select id={`${activityFormId}_c3`} style={{ width: '70px',  fontSize: '0.8em' }}
                                            onChange={(e) => {                                                
                                                changeValue(activity.uuid, 'value3', e.target.value.toString(), 3);
                                            }} 
                                            value={ showValue(evaluations[0] ? evaluations[0].value3 : '', activity) }>
                                            <option value={''}>
                                                Sin dato
                                            </option>
                                            {
                                                values.map((v, key) => {
                                                    return(
                                                        <option value={v} key={`s3_${key}`}>
                                                            {v}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                        :
                                        <input 
                                            onChange={(e) => {
                                                changeValue(activity.uuid, 'value3', e.target.value, 3);
                                            }} 
                                            value={ showValue(evaluations[0] ? evaluations[0].value3 : '', activity) }
                                            id={`${activityFormId}_c3`}
                                            placeholder="Sin dato" 
                                            type="number" 
                                            style={{ width: '70px', fontSize: '0.8em' }} />
                                        :
                                            <div>N.A.</div>
                                    }
                                    </div>
                                </td>
                                <td style={{ fontSize: '0.8em' }}>
                                    {
                                        type === 'PRAC' && key === 0 && activity.additional_percent > 0 &&
                                        <input
                                        id={`${activityFormId}_pacient_c4`}
                                        value={ evaluations[0] ? evaluations[0].pacient_info4 ? evaluations[0].pacient_info4 : '' : '' }
                                        onChange={(e) => {
                                            changeValuePlain(activity.uuid, 'pacient_info4', e.target.value, 4);                                          
                                        }} 
                                        placeholder="Paciente" type="text" style={{ width: '70px', fontSize: '0.8em', marginBottom: '7px' }} />
                                    }
                                    {
                                        activity.additional_percent > 0 &&
                                        <input 
                                        id={`${activityFormId}_c4`}
                                        onChange={(e) => {
                                            changeAdditionalValue(activity.uuid, 'value4', e.target.value, 4);
                                        }} 
                                        value={ showAdditionalValue(evaluations[0] ? evaluations[0].value4 : '', activity) }
                                        placeholder="Sin dato" type="number" style={{ width: '70px', fontSize: '0.8em' }} />
                                    }
                                </td>
                                <td style={{ fontSize: '0.8em' }}>
                                    {
                                        type === 'PRAC' && key === 0 && activity.additional_percent > 0 &&
                                        <input
                                        id={`${activityFormId}_pacient_c5`}
                                        value={ evaluations[0] ? evaluations[0].pacient_info5 ? evaluations[0].pacient_info5 : '' : '' }
                                        onChange={(e) => {
                                            changeValuePlain(activity.uuid, 'pacient_info5', e.target.value, 5);                                          
                                        }} 
                                        placeholder="Paciente" type="text" style={{ width: '70px', fontSize: '0.8em', marginBottom: '7px' }} />
                                    }
                                    {
                                        activity.additional_percent > 0 &&
                                        <input 
                                            id={`${activityFormId}_c5`}
                                            onChange={(e) => {
                                                changeAdditionalValue(activity.uuid, 'value5', e.target.value, 5);
                                            }} 
                                            value={ showAdditionalValue(evaluations[0] ? evaluations[0].value5 : '', activity) }
                                            placeholder="Sin dato" type="number" style={{ width: '70px', fontSize: '0.8em' }} />
                                    }
                                </td>
                                <td style={{ fontSize: '0.8em' }}>
                                    <Button variant="success" size="sm"
                                        onClick={() => {
                                            const body = buildEvaluations(activityFormId, evaluations[0].uuid, activity);
                                            evaluateHook.evaluate(body);
                                        }} 
                                    >
                                        ACTUALIZAR
                                    </Button>
                                </td>
                                <td style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                                    <span>
                                        {Number(total).toFixed(4)}
                                    </span>
                                    <div className="flex-col flex-center">
                                    {
                                        evaluationUpdatedBy && 
                                        <UpdatedByModal updatedBy={evaluationUpdatedBy} />
                                    }
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
}

export default TableCalification;