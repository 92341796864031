import { editPerson, createPerson, inactivePerson, activePerson } from "../../_services/person.service";
import { validatePerson } from "./model";

export const createPersonEvent = async (body) => {
    validatePerson(body);
    return await createPerson(body);
}

export const editPersonEvent = async (id, body) => {
    validatePerson(body);
    return await editPerson(id, body);
}

export const inactivePersonEvent = async (id) => {
    return await inactivePerson(id);
}

export const activePersonEvent = async (id) => {
    return await activePerson(id);
}