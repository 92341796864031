import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Link } from "react-router-dom";
import { paths } from "../../../app_modules";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useCompetencyActivityCreateEdit } from "../../../../_hooks/competency_activity/useAllCompetencyActivityCreateEdit.hook";
import { getCompetencyName } from "../../../../_utils/storage_handler";

function CompetencyActivityForm(){

    const creationHook = useCompetencyActivityCreateEdit();

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <Link style={{ marginRight: '30px', width: '100%' }} to={paths.competency_activity}>
                        <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
                    </Link>
                    <span className="vp-title">Actividad de competencia</span>
                </div>
            </div>
            <hr />
            <span><strong>Competencia: </strong>{getCompetencyName()}</span>
            <div style={{ width: '100%' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='form-label'>Nombre</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity?.name }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.name = e.target.value;
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                            <div className='form-label'>Cantidad de evaluaciones requeridas</div>
                            <select 
                                className='vp-input'
                                value={ creationHook.entity?.evaluations_number }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.evaluations_number = e.target.value;
                                    creationHook.setEntity(newEnt);
                                }}
                            >
                                {
                                    Array.from({ length: 3 }).map((_, index) => (
                                        <option value={index + 1} key={`op_${index}`}>
                                            {index + 1}
                                        </option>
                                    ))
                                }
                            </select>
                        </Col>
                        <Col lg={6}>
                            <div className='form-label'>Puntaje máximo</div>
                            <input 
                                className='vp-input' 
                                type='number'
                                value={ creationHook.entity?.max_point }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.max_point = e.target.value;
                                    creationHook.setEntity(newEnt);
                                }}
                            />
                            <div className='form-label'>Tipo de calificación</div>
                            <Form.Check
                                type="switch"
                                label={creationHook.entity.select_evaluation === 1 ? 'Selección de valores' : 'Evaluación abierta'}
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity}
                                    newEnt.select_evaluation = e.target.checked === true ? 1 : 0;
                                    creationHook.setEntity(newEnt);
                                }}
                                checked={ creationHook.entity.select_evaluation === 1 }
                            />            
                        </Col>
                        <Col lg={6}>
                            <div className='form-label'>Porcentaje adicionales</div>
                            <input 
                                className='vp-input' 
                                type='number'
                                value={ creationHook.entity?.additional_percent }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.additional_percent = e.target.value;
                                    creationHook.setEntity(newEnt);
                                }}
                            />          
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}></Col>
                        <Col lg={4}>
                            <Button onClick={ creationHook.uuid ? () => creationHook.edit() : () => creationHook.create() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                { creationHook.uuid ? 'Editar' : 'Crear' }
                            </Button>
                        </Col>
                        <Col lg={4}></Col>
                    </Row>
                </Container>
            </div>
        </MainContent>
    );
}

export default CompetencyActivityForm;