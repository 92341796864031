import { useEffect, useState } from "react"
import { findAllActiveFormNotIdEvent } from "../../_events/form/find.event";
import { message } from "antd";

export const useActiveFormNotId = (id) => {

    const [ data, setData ] = useState([]);

    useEffect(() => {
        findAllActiveFormNotIdEvent(id)
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            message.error(err.message);
        });
    }, [id]);

    return {
        data
    }
}