export const validateCreateUserRequest = (body) => {
    const { name, email, type, login, rol } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(email === null || email === '') throw new Error("Correo vacío");
    if(login === null || login === '') throw new Error("Usuario vacío");
    if(type === null || type === '') throw new Error("Tipo vacío");
    if(rol === null || rol === '') throw new Error("Rol vacío");
}

export const transformEntityCreateUserRequest = (entity) => {
    let newEnt = {...entity};
    newEnt.rol = newEnt.rol.cod;
    return newEnt;
}

export const transformEntityCreateUserRequestToUser = (entity) => {
    let newEnt = {...entity, password: '', personObj: {}};
    newEnt.personObj.name = newEnt.name;
    newEnt.personObj.email = newEnt.email;
    newEnt.personObj.type = newEnt.type;
    return newEnt;
}

export const buildEmptyCreateUserRequest = () => {
    return{
        login: '',
        password: '',
        rol: '',
        person: '',
        name: '',
        email: '',
        type: '',
    };
}