export const term_percents = {
    '1': 30,
    '2': 30,
    '3': 40, 
}

export const terms_total_points = {
    '1': 150,
    '2': 150,
    '3': 200, 
}