import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptyCompetencyActivity, transformEntityCompetencyActivity } from "../../_events/competency_activity/model";
import { findCompetencyActivityByIdEvent } from "../../_events/competency_activity/find.event";
import { createCompetencyActivityEvent, editCompetencyActivityEvent } from "../../_events/competency_activity/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { getCompetencyId } from "../../_utils/storage_handler";

export const useCompetencyActivityCreateEdit = () => {

    const query = useQuery();
    const uuid = query.get("id");

    const [ entity, setEntity ] = React.useState(buildEmptyCompetencyActivity(getCompetencyId()));

    const create = () => {
        createCompetencyActivityEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyCompetencyActivity(getCompetencyId()));
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editCompetencyActivityEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findCompetencyActivityByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityCompetencyActivity(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit };
}