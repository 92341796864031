export const validateFormTemplate = (body) => {
    const { semester } = body;
    if(semester === null || semester === '') throw new Error("Semestre vacío");

}

export const transformEntityFormTemplate = (entity) => {
    let newEnt = {...entity};
    if(newEnt.semester) newEnt.semester = newEnt.semester.cod;
    return newEnt;
}

export const buildEmptyFormTemplate = (semester = '') => {
    return{
        semester,
    };
}