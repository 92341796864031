import LogoDoctor from '../../../../../_assets/imgs/doctor.webp';

export function StatusInteractive({ calification }){
    const progressPercent = Math.ceil(Math.round(calification * 100 / 5));

    return(
        <div>
            <div style={{ width: '100%' }}>
                <div style={{ width: `${progressPercent < 5 ? 5 : progressPercent > 100 ? 100 : progressPercent}%`, display: 'flex', flexDirection: 'row-reverse' }}>
                    <img style={{ width: '38px', height: '80px' }} src={LogoDoctor} alt='DOCTOR' />
                </div>
            </div>
            <div style={{ width: '100%', height: '10px', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '30%', backgroundColor: 'red' }}>

                </div>
                <div style={{ width: '30%', backgroundColor: 'orange' }}>

                </div>
                <div style={{ width: '40%', backgroundColor: 'green' }}>

                </div>
            </div>
        </div>
    );
}