import React from "react";
import { findAllAcademicPartWithFormByStudentFormAndByTermEvent } from "../../_events/academic_parts/find.event";
import { alertError } from "../../_utils/alerts";

export const useAcademicPartAllWithFormBySFAndTerm = (sf, term) => {

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [ selected, setSelected ] = React.useState(sf);

    const loadData = (i, t) => {
        setLoading(true);
        findAllAcademicPartWithFormByStudentFormAndByTermEvent(i, t)
        .then(json => {
            setData(json.list);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        
        loadData(selected.uuid, term);
    // eslint-disable-next-line
    }, [term]);

    return { loading, data, selected, setData, setSelected, loadData };
}