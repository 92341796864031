export const validatePerson = (body) => {
    const { name, email, type } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(email === null || email === '') throw new Error("Correo vacío");
    if(type === null || type === '') throw new Error("Tipo vacío");
}

export const transformEntityPerson = (entity) => {
    let newEnt = {...entity};
    newEnt.type = newEnt.type.cod;
    return newEnt;
}

export const buildEmptyPerson = (type = '') => {
    return{
        name: '',
        email: '',
        type
    };
}