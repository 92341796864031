import React from "react";
import { Outlet } from "react-router-dom";
import MainNavbar from "../../widgets/navbar/main_navbar";
import { Col, Container, Row } from "react-bootstrap";

const ContentTemplate = () => {

    return(
        <>
            <MainNavbar /> 
            <Container fluid style={{ padding: '5px 10px', backgroundColor: '#fff' }} >
                <Row>
                    <Col lg={12}>
                        <Outlet />
                    </Col>
                </Row>
            </Container> 
        </>
    );
};

export default ContentTemplate;