import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { path_modules } from '../app_modules';
import { useInactiveCreateUserRequest } from '../../_hooks/create_user_request/use-inactive-create-user-request.hook';
import { Result } from 'antd';

function EndRegister(){

    const inactiveHook = useInactiveCreateUserRequest();

    return(
        <Container>
            <Row>
                <Col lg={12}>
                    <div className='flex-col flex-center form-label'>Usuario creado con éxito</div>
                    {
                        inactiveHook.loading ? <div className='flex-col flex-center'><div className='vp-spinner'></div></div>
                        :
                        <Result
                            status="success"
                            title="Ya puedes ingresar"
                            subTitle="Link de acceso:"
                            extra={[
                                <Link to={`${path_modules.login.fullPath}`}>
                                    Ir a login
                                </Link>,
                            ]}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default EndRegister;