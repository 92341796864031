import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useActiveForms } from "./hook";
import SemestersMenu from "./semesters_menu";
import CreateForm from "./create_form";
import CardInfo from "./card_info";

function Info(){

    const hook = useActiveForms();

    return(
        <Container fluid>
            <Row>
                <Col lg={2}>
                    <SemestersMenu
                        hook={hook}
                    />
                </Col>
                <Col lg={10}>
                    {
                        hook.loading ?
                            <div className="flex-col flex-center" style={{ margin: '30px 0px' }}>
                                <div className="vp-spinner"></div>
                            </div>
                        :
                        hook.data === null ?
                        <CreateForm
                            semesterCod={hook.selected}
                            reload={() => hook.loadData(hook.selected)}
                        />
                        :
                        <CardInfo
                            data={hook.data}
                            reload={() => hook.loadData(hook.selected)}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default Info;