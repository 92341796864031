import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useEditProfile } from "../../../../_hooks/user/useEditProfile.hook";
import { getUserId } from "../../../../_utils/storage_handler";
import { useEditPassword } from "../../../../_hooks/user/useEditPassword.hook";

function ProfilePage(){

    const creationHook = useEditProfile();
    const changePasswordHook = useEditPassword(getUserId());

    return(
        <MainContent>
            <div className="flex-row" style={{ marginTop: '15px' }}>
                <div style={{ width: '100%' }}>
                    <span className="vp-title">{creationHook.person.name}</span>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%' }}>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='form-label'>Nombre completo</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.person?.name }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.person};
                                    newEnt.name = e.target.value;
                                    creationHook.setPerson(newEnt);                               
                                }}
                            />
                            <div className='form-label'>Correo</div>
                            <input 
                                className='vp-input' 
                                type='email'
                                value={ creationHook.person?.email }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.person};
                                    newEnt.email = e.target.value;
                                    creationHook.setPerson(newEnt);                                 
                                }}
                            />
                            <Button onClick={ () => creationHook.editPerson() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                Editar
                            </Button>
                        </Col>
                        <Col lg={6}>
                        <div className='form-label'>Usuario</div>
                            <input 
                                className='vp-input' 
                                type='text'
                                value={ creationHook.entity.login }
                                onChange={(e) => {
                                    let newEnt = {...creationHook.entity};
                                    newEnt.login = e.target.value;
                                    creationHook.setEntity(newEnt);                             
                                }}
                            />
                            <Button onClick={ () => creationHook.edit() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                Editar
                            </Button>
                            <div>
                                <hr />
                                <div className='form-label'>Cambiar contraseña</div>
                                <input 
                                    className='vp-input' 
                                    type='password'
                                    value={ changePasswordHook.entity.password }
                                    onChange={(e) => {
                                        let newEnt = {...changePasswordHook.entity};
                                        newEnt.password = e.target.value;
                                        changePasswordHook.setEntity(newEnt);                             
                                    }}
                                />
                                <Button onClick={ () => changePasswordHook.edit() } variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                                    Cambiar contraseña
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </MainContent>
    );
}

export default ProfilePage;