import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS, DELETE_OPTIONS } from './base.service';

const BASE_PATH = '/evaluation';

export const findEvaluationById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllEvaluation = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createEvaluation = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const evaluate = async (body) => {
    return await handleFetch(`${BASE_PATH}/evaluate`, POST_OPTIONS, body);
}

export const editEvaluation = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllEvaluationPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const deleteEvaluationByFormAndActivityAndTerm = async (form, activity, term) => {
    return await handleFetch(`${BASE_PATH}/form/activity/term/${form}/${activity}/${term}`, DELETE_OPTIONS);
}

export const changeOrderEvaluation = async (body) => {
    return await handleFetch(`${BASE_PATH}/change-order`, POST_OPTIONS, body);
}

export const copyByTermEvaluation = async (termFrom, termTo, form) => {
    return await handleFetch(`${BASE_PATH}/copy-from-term/${termFrom}/${termTo}/${form}`, POST_OPTIONS);
}