import { findAllFormTemplatePaged, findFormTemplateById, findAllFormTemplate, findFormTemplateBySemester } from "../../_services/form_template.service";

export const findAllFormTemplateEvent = async () => {
    return await findAllFormTemplate();
}

export const findAllFormTemplatePagedEvent = async (page, limit = 8) => {
    return await findAllFormTemplatePaged(page, limit);
}

export const findFormTemplateByIdEvent = async (id) => {
    return await findFormTemplateById(id);
}

export const findFormTemplateBySemesterEvent = async (semesser) => {
    return await findFormTemplateBySemester(semesser);
}