import { createCreateUserRequest, inactiveCreateUserRequestById } from "../../_services/create_user_request.service";
import { validateCreateUserRequest } from "./model";

export const createCreateUserRequestEvent = async (body) => {
    validateCreateUserRequest(body);
    return await createCreateUserRequest(body);
}

export const inactiveCreateUserRequestEvent = async (id) => {
    return await inactiveCreateUserRequestById(id);
}
