import { findAllRolPaged, findRolById, findAllRol } from "../../_services/roles.service";

export const findAllRolPagedEvent = async (page, limit = 8) => {
    return await findAllRolPaged(page, limit);
}

export const findRolByIdEvent = async (id) => {
    return await findRolById(id);
}

export const findAllRolEvent = async () => {
    return await findAllRol();
}