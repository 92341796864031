import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import StudentsMenu from "./students_menu";
import ActivitiesTable from "./activities_table";
import { useAcademicPartAllWithFormBySFAndTerm } from "../../../../../_hooks/academic_part/useAcademicPartAllWithFormBySFAndTerm.hook";
import ObservationModal from "./observation_modal";

function TermsInfo({ term, students }){

    const studentFormEvaluationsHook =  useAcademicPartAllWithFormBySFAndTerm(students ? students.length > 0 ? {...students[0]} : '' : '', term) ;

    return(
        <Container fluid>
            <Row>
                <Col lg={2}>
                </Col>
                <Col lg={10}>
                    {
                        studentFormEvaluationsHook.selected !== '' &&
                        <ObservationModal
                            studentId={studentFormEvaluationsHook.selected.uuid}
                            term={term}
                        />
                    }
                </Col>
                <Col lg={2}>
                    <StudentsMenu
                        students={students}
                        selected={studentFormEvaluationsHook.selected.uuid}
                        loadData={(studentForm) => {
                            studentFormEvaluationsHook.setSelected(studentForm);
                            studentFormEvaluationsHook.loadData(studentForm.uuid, term);
                            //console.log(`ReCargar ${studentForm.uuid}, ${term}`);
                        }}
                    />
                </Col>
                <Col lg={10}>
                    <hr />
                    {
                        studentFormEvaluationsHook.selected !== '' &&
                        <div>
                            <strong style={{ fontSize: '0.9em' }}>Corte {term}</strong>
                            {
                                studentFormEvaluationsHook.loading ?
                                <div className="flex-col flex-center">
                                    <div className='vp-spinner' />
                                </div>
                                :
                                <ActivitiesTable
                                    hook={studentFormEvaluationsHook}
                                    term={term}
                                />
                            }
                        </div>                        
                    }                
                </Col>
            </Row>
        </Container>
    );
}

export default TermsInfo;