import React from "react";
import MainContent from "../../../../widgets/templates/main_content";
import Table from "./table";
import { path_modules, paths } from "../../../app_modules";
import AdminHeader from "../../../../widgets/templates/admin_header";
import { getCompetencyName } from "../../../../_utils/storage_handler";
import { Link } from "react-router-dom";

function CompetencyActivityPage(){

    return(
        <MainContent>
            <Link style={{ marginRight: '30px', width: '100%' }} to={path_modules.content.children.parametrization.children.competency.fullPath}>
                <svg width={20} height={20} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#074C00" stroke="#074C00"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm6 50L18 32l20-20v40z" fill="#074C00"></path></g></svg>
            </Link>
            <AdminHeader 
                title="Actividades de competencia" 
                masivPath={paths.competency_activity_form} 
                createPath={paths.competency_activity_form} 
            />
            <span><strong>Competencia: </strong>{getCompetencyName()}</span>
            <hr />
            <div style={{ width: '100%' }}>
                <Table />
            </div>
        </MainContent>
    );
}

export default CompetencyActivityPage;