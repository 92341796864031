import React from "react";
import { findAllAcademicPartWithFormByIdAndByTermWithTotalEvent } from "../../_events/academic_parts/find.event";
import { alertError } from "../../_utils/alerts";

export const useAcademicPartAllWithFormByIdAndTermTotal = (id, term) => {

    const [data, setData] = React.useState([]);
    const [ total, setTotal ] = React.useState(0);

    const loadData = (i, t) => {
        findAllAcademicPartWithFormByIdAndByTermWithTotalEvent(i, t)
        .then(json => {
            setData(json.data.tree.list);
            setTotal(json.data.tree.total);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData(id, term);
    }, [id, term]);

    return { data, total, loadData };
}