import { editProcedure, createProcedure } from "../../_services/procedure.service";
import { validateProcedure } from "./model";

export const createProcedureEvent = async (body) => {
    validateProcedure(body);
    return await createProcedure(body);
}

export const editProcedureEvent = async (id, body) => {
    validateProcedure(body);
    return await editProcedure(id, body);
}