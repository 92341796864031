export const validateAcademicConcept = (body) => {
    const { name, part, total_points, semester } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(total_points === null || total_points === '') throw new Error("Total puntos vacío");
    if(part === null || part === '') throw new Error("Parte académica vacío");
    if(semester === null || semester === '') throw new Error("Semestre vacío");

}

export const transformEntityAcademicConcept = (entity) => {
    let newEnt = {...entity};
    newEnt.part = newEnt.part?.cod;
    newEnt.semester = newEnt.semester?.cod;
    return newEnt;
}

export const buildEmptyAcademicConcept = () => {
    return{
        name: '',
        total_points: '',
        state: 1,
        part: '',
        color_id: '#ffffff',
        semester: '',
    };
}