import { editActivityForm, createActivityForm, addActivityFormActivity, addStudentToForm } from "../../_services/activity_form.service";
import { validateActivityForm } from "./model";

export const createActivityFormEvent = async (body) => {
    validateActivityForm(body);
    return await createActivityForm(body);
}

export const addActivityFormActivityEvent = async (body) => {
    validateActivityForm(body);
    return await addActivityFormActivity(body);
}

export const addStudentToFormEvent = async (body) => {
    return await addStudentToForm(body);
}

export const editActivityFormEvent = async (id, body) => {
    validateActivityForm(body);
    return await editActivityForm(id, body);
}