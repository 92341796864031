import { Card } from 'antd';
import { StatusInteractive } from './status_interactive';
import { terms_total_points } from '../../../../../_config/term_percents';

export function TotalStatus({ terms }){

    var calif1 = 0;
    var calif2 = 0;
    var calif3 = 0;
    for (let i = 0; i < terms.length; i++) {
        const term = terms[i];

        // Term total points
        //let totalPoints = 0;
        // Term total points earned
        let points = 0;

        for (let j = 0; j < term.academic_part.length; j++) {
            const ap = term.academic_part[j];
            for (let k = 0; k < ap.concepts.length; k++) {
                const c = ap.concepts[k];

                // Add term total points by Academic Concept configuration
                for (let l = 0; l < c.groups.length; l++) {
                    const g = c.groups[l];
                    for (let m = 0; m < g.competencies.length; m++) {
                        const co = g.competencies[m];
                        for (let index = 0; index < co.activities.length; index++) {
                            let total = 0;
                            const activity = co.activities[index];
                            const evaluations = activity.forms[0]?.evaluations;
                            //totalPoints += Number(activity.max_point) * Number(activity.evaluations_number);
                            total += evaluations[0] ? Number(evaluations[0].value) : 0;                                                                            
                            total += evaluations[0] ? Number(evaluations[0].value2) : 0;                                                                            
                            total += evaluations[0] ? Number(evaluations[0].value3) : 0;                                                                            
                            total += evaluations[0] ? Number(evaluations[0].value4) : 0;
                            total += evaluations[0] ? Number(evaluations[0].value5) : 0;

                            // Add term total points earned by califications
                            points += total;

                        }
                    }
                }
            }
        }
        if(term.term === '1') calif1 = points * 5 / terms_total_points['1'];
        else if(term.term === '2') calif2 = points * 5 / terms_total_points['2'];
        else if(term.term === '3') calif3 = points * 5 / terms_total_points['3']; 
    }

    return(
        <div>
            <div style={{ width: '100%' }}>
                <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}> Progreso total del semestre</span>
            </div>
            <Card style={{ margin: '15px 0px' }}>
                <div className='flex-col flex-center'>
                    <div style={{ fontSize: '2em', fontWeight: 'bold' }}>Total: { Number((calif1 * 0.3) + (calif2 * 0.3) + (calif3 * 0.4)).toFixed(1)  }</div>
                    <div style={{ fontSize: '1.4em' }}>Corte 1: { Number(calif1).toFixed(1) } </div>
                    <div style={{ fontSize: '1.4em' }}>Corte 2: { Number(calif2).toFixed(1)  } </div>
                    <div style={{ fontSize: '1.4em' }}>Corte 3: { Number(calif3).toFixed(1)  } </div>
                    
                </div>
            </Card>
            <StatusInteractive calification={ (calif1 * 0.3) + (calif2 * 0.3) + (calif3 * 0.4) } />
        </div>
    );
}