import { findAllUserPaged, findUserById, findAllUser } from "../../_services/user.service";

export const findAllUserPagedEvent = async (page, limit = 8) => {
    return await findAllUserPaged(page, limit);
}

export const findUserByIdEvent = async (id) => {
    return await findUserById(id);
}

export const findAllUserEvent = async () => {
    return await findAllUser();
}