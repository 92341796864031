import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useAcademicPartAllFormTemplateNotIncludeBySemester } from "../../../../_hooks/academic_part/useAcademicPartAllFormTemplateNotIncludeBySemester.hook";
import { useActivityFormTemplateCreateEdit } from "../../../../_hooks/activity_form_template/useActivityFormTemplateCreateEdit.hook";

function AddActivityModal({ semester, formId, loadFunc }){

    const [show, setShow] = React.useState(false);

    const createHook = useActivityFormTemplateCreateEdit(formId);

    const dataNotInclude = useAcademicPartAllFormTemplateNotIncludeBySemester(semester);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        dataNotInclude.loadData(semester);
        setShow(true);
    }

    return(
        <div>
            <Button onClick={handleShow} variant="success" size="sm">
                Agregar actividad
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <ModalBody>
                    <div style={{ fontWeight: 'bold' }}>Actividades</div>
                    <hr/>
                    {
                        dataNotInclude.data.map((ap, key) => {
                            return(
                                <div key={`ap_${key}`}>
                                    <div style={{ fontWeight: 'bold', fontSize: '0.8em' }} >
                                        - {ap.name}                                        
                                    </div>
                                    {
                                        ap.concepts.map((c, key) => {
                                            return(
                                                <div key={`c_${key}`}>
                                                    <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                        - {c.name}                                        
                                                    </div>
                                                    {
                                                        c.groups.map((g, key) => {
                                                            return(
                                                                <div key={`g_${key}`}>
                                                                    {
                                                                        g.competencies.map((co) => {
                                                                            return(
                                                                                <div key={`co_am_${key}`}>
                                                                                    <div style={{ marginLeft: '40px', fontSize: '0.8em' }}>
                                                                                        - {co.name}                                        
                                                                                    </div>
                                                                                    {
                                                                                        co.activities.map((a, key) => {
                                                                                            return(
                                                                                                <div key={`a_${key}`}>
                                                                                                    <div style={{ marginLeft: '80px' }} className="flex-row">
                                                                                                        <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                createHook.createAndLoad(a.uuid, () => {
                                                                                                                    loadFunc();
                                                                                                                    dataNotInclude.loadData(semester);
                                                                                                                });
                                                                                                            }} >
                                                                                                                <svg width="20px" height="20px" viewBox="-0.96 -0.96 33.92 33.92" version="1.1" fill="#36d416" stroke="#36d416"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>plus-circle</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" > <g id="Icon-Set-Filled"  transform="translate(-466.000000, -1089.000000)" fill="#3aba21"> <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z" id="plus-circle"> </path> </g> </g> </g></svg>
                                                                                                        </span>
                                                                                                        <span style={{ marginLeft: '15px', marginTop: '5px', fontSize: '0.8em' }}>{a.name}</span>                                                                                      
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                
                            );
                        })
                    }
                </ModalBody>
            </Modal>
        </div>
    );

}

export default AddActivityModal;