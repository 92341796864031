import React from "react";
import { useQuery } from "../../_utils/url_query_hook";
import { buildEmptyPerson, transformEntityPerson } from "../../_events/person/model";
import { findPersonByIdEvent } from "../../_events/person/find.event";
import { createPersonEvent, editPersonEvent } from "../../_events/person/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const usePersonCreateEdit = (type = '') => {

    const query = useQuery();
    const uuid = query.get("id");

    const [ entity, setEntity ] = React.useState(buildEmptyPerson(type));

    const create = () => {
        createPersonEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(buildEmptyPerson(type));
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editPersonEvent(uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(uuid){
            findPersonByIdEvent(uuid)
            .then(json => {
                setEntity(transformEntityPerson(json.data));
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [uuid]);

    return { entity, setEntity, uuid, create, edit };
}