import { findAllCompetencyActivityPaged, findCompetencyActivityById, findAllCompetencyActivity } from "../../_services/competency_activity.service";

export const findAllCompetencyActivityPagedEvent = async (page, limit = 8) => {
    return await findAllCompetencyActivityPaged(page, limit);
}

export const findCompetencyActivityByIdEvent = async (id) => {
    return await findCompetencyActivityById(id);
}

export const findAllCompetencyActivityEvent = async () => {
    return await findAllCompetencyActivity();
}