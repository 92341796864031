import { evaluateEvent } from "../../_events/evaluation/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useEvaluate = () => {

    const evaluate = (body) => {
        evaluateEvent(body)
        .then(json => {
            if(json.data) alertSuccess("Guardado");
            else alertError("Ocurrió un error");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { evaluate };
}