import { useModal } from "../../widgets/modal/useModalHook";
import { useCompetencyCreateDuplication } from "./useCompetencyCreateDuplication.hook";

export const useCompetencyDuplicationModal = () => {

    const modal = useModal();
    const duplication = useCompetencyCreateDuplication();

    const hanldeOpen = (uuid) => {
        modal.hanldeOpen(() => {
            duplication.setEntity({
                ...duplication.entity,
                uuid,
            })
        });
    }

    const duplicate = (reload) => {
        duplication.duplicate(() => {
            modal.hanldeClose();
            if(reload) reload();
        });
    }

    return { modal, duplication, hanldeOpen, duplicate };
}