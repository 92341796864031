export const validateProcedure = (body) => {
    const { student, competency } = body;
    if(student === null || student === '') throw new Error("Estudiante vacío");
    if(competency === null || competency === '') throw new Error("Competencia vacía");
}

export const transformEntityProcedure = (entity) => {
    let newEnt = {...entity};
    newEnt.student = newEnt.student.uuid;
    newEnt.competency = newEnt.competency.uuid;
    return newEnt;
}

export const buildEmptyProcedure = () => {
    return{
        procedures_t1: null,
        procedures_t2: null,
        procedures_t3: null,
        student: '',
        competency: '',
    };
}