import React from "react";
import { findAllSemesterEvent } from "../../../../../_events/semester/find.event";
import { findActiveFormBySemesterEvent } from "../../../../../_events/form/find.event";
import { alertError } from "../../../../../_utils/alerts";

export const useActiveForms = () => {

    const [semesters, setSemesters] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [ data, setData ] = React.useState(null);
    const [ selected, setSelected ] = React.useState('');

    const loadData = (semester) => {
        setLoading(true);
        findActiveFormBySemesterEvent(semester)
        .then(json => {
            setData(json.data);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        findAllSemesterEvent()
        .then(json => {
            setSemesters(json.list);
            if (json.list.length > 0) {
                setSelected(json.list[0].cod);
                loadData(json.list[0].cod);
            };
        })
        .catch(err => {
            alertError(err.message);
        });
    }, []);

    return { loading, semesters, setSemesters, selected, setSelected, data, setData, loadData };
}