import React from "react";
import { findAllTeachersEvent } from "../../_events/person/find.event";
import { alertError } from "../../_utils/alerts";

export const useAllTeachers = () => {

    const [data, setData] = React.useState([]);

    const loadData = () => {
        findAllTeachersEvent()
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        loadData();
    }, []);

    return { data, loadData };
}