import React from "react";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { editUserPasswordEvent } from "../../_events/users/create.event";

export const useEditPassword = (uuid) => {

    const [ entity, setEntity ] = React.useState({uuid, password: ''});

    const edit = () => {
        editUserPasswordEvent(entity)
        .then(json => {
            alertSuccess("Actualizado");
            setEntity({uuid, password: ''});
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { entity, setEntity, edit };
}