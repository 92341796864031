import React from "react";
import PagedTable from "../../../../../widgets/tables/paged_table";

function TableStudents({ currentPage, changePage = () => {}, data, count, deleteStudent = (uuid) => {} }){

    const columns = [
        {
            key: 'student',
            name: 'Nombre',
            label: false,
            render: (param) => {
                return(
                    <span>{param.student?.name}</span>
                );
            }
        },
        {
            key: 'student',
            name: 'Correo',
            label: false,
            render: (param) => {
                return(
                    <span>{param.student?.email}</span>
                );
            }
        },
        {
            key: '',
            name: 'Eliminar',
            label: false,
            render: (param, index, total) => {
                if(total === 1){
                    return(<></>);
                }
                return(
                    <div className="flex-row">
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                deleteStudent(param.uuid);
                            }}
                        >
                        <svg width="20px" height="20px" viewBox="-1.6 -1.6 35.20 35.20" version="1.1" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>minus-circle</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> <g id="Icon-Set-Filled" transform="translate(-518.000000, -1089.000000)" fill="#e01f1f"> <path d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z" id="minus-circle"> </path> </g> </g> </g></svg>
                        </span>
                    </div>
                );
            }
        },
    ];

    return(
        <PagedTable
            columns={columns}
            data={data[0]}
            total={data[1]}
            dataPerPage={count}
            currentPage={currentPage}
            onChangePage={(page) => changePage(page)}
        />
    );
}

export default TableStudents;