import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/procedure';

export const findProcedureById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllProcedure = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createProcedure = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editProcedure = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllProcedurePaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}