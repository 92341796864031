import { 
    editCompetency, 
    createCompetency, 
    changeStateById,
    createByDuplicateCompetency 
} from "../../_services/competency.service";
import { validateCompetency, validateDuplicationCompetency } from "./model";

export const createCompetencyEvent = async (body) => {
    validateCompetency(body);
    return await createCompetency(body);
}

export const editCompetencyEvent = async (id, body) => {
    validateCompetency(body);
    return await editCompetency(id, body);
}

export const changeStateByIdCompetencyEvent = async (id, state) => {
    return await changeStateById(id, state);
}

export const createByDuplicateCompetencyEvent = async (body) => {
    validateDuplicationCompetency(body);
    return await createByDuplicateCompetency(body);
}