import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/academic-part';

export const findAcademicPartById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const findAllAcademicPart = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createAcademicPart = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editAcademicPart = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllAcademicPartWithFormTemplateBySemester = async (semester) => {
    return await handleFetch(`${BASE_PATH}/all/form-template/semester/${semester}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormTemplateNotIncludeBySemester = async (semester) => {
    return await handleFetch(`${BASE_PATH}/all/form-template-not-include/semester/${semester}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormByIdAndByTerm = async (id, term) => {
    return await handleFetch(`${BASE_PATH}/all/form/id/term/${id}/${term}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormByIdAndByTermWithTotal = async (id, term) => {
    return await handleFetch(`${BASE_PATH}/tree/form/id/term/${id}/${term}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormByStudentFormAndByTerm = async (sf, term) => {
    return await handleFetch(`${BASE_PATH}/all/form/sf/term/${sf}/${term}`, GET_OPTIONS);
}

export const downloadExcelByStudentForm = async (sf) => {
    return await handleFetch(`${BASE_PATH}/excel/student/sf/${sf}`, GET_OPTIONS);
}

export const downloadExcelStudentsByForm = async (form) => {
    return await handleFetch(`${BASE_PATH}/excel/students/${form}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormByStudentFormGroupedByTerm = async (sf) => {
    return await handleFetch(`${BASE_PATH}/find/grouped-term/student/sf/${sf}`, GET_OPTIONS);
}

export const findAllAcademicPartWithFormNotIncludeBySemester = async (semester, term) => {
    return await handleFetch(`${BASE_PATH}/all/form-not-include/semester/term/${semester}/${term}`, GET_OPTIONS);
}

export const findAllAcademicPartPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}