export const validateCompetencyActivity = (body) => {
    const { name, competency, evaluations_number, max_point } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(competency === null || competency === '') throw new Error("Competencia vacía");
    if(evaluations_number === null || evaluations_number === '' || evaluations_number === 0) throw new Error("Cantidad de evaluaciones vacía");
    if(max_point === null || max_point === '' || max_point === 0) throw new Error("Puntaje máximo vacío");

}

export const transformEntityCompetencyActivity = (entity) => {
    let newEnt = {...entity};
    newEnt.competency = newEnt.competency?.uuid;
    return newEnt;
}

export const buildEmptyCompetencyActivity = (competency = '') => {
    return{
        name: '',
        state: 1,
        competency,
        max_point: null,
        additional_percent: 20,
        select_evaluation: 0,
        evaluation_values: '',
        evaluations_number: 2,
    };
}