import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import RegisterForm from "./form";

function RegisterPage(){

    return(
        <Container fluid>
            <Row style={{ marginTop: '40px' }}>
                <Col sm={4}>
                </Col>
                <Col sm={4}>
                    <Card>
                        <Card.Body>
                            <div className="flex-col flex-center">
                                <img src={require("../../_assets/imgs/logo_no_bg.png")} width={350} alt="UEB"/>
                            </div>
                            <hr />
                            <RegisterForm />
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterPage;