import React from "react";
import { useQuery } from "../../../../../_utils/url_query_hook";
import { useStudentFormByFormPaged } from "../../../../../_hooks/student_form/useStudentFormByFormPaged.hook";
import { Col, Container, Row } from "react-bootstrap";
import TableStudents from "./table_students";
import { useStudentNotInActiveFormPaged } from "../../../../../_hooks/person/useStudentNotInActiveFormPaged.hook";
import TableToAdd from "./table_to_add";
import { useDeleteStudentForm } from "../../../../../_hooks/student_form/useDeleteStudentForm.hook";
import { useAddStudentToForm } from "../../../../../_hooks/activity_form.hook.js/useAddStudentToForm.hook";

function InfoStudents(){

    const query = useQuery();
    const form = query.get('id');
    const COUNT = 10;
    const studentsHook = useStudentFormByFormPaged(form, 0, COUNT);
    const studentsToAddHook = useStudentNotInActiveFormPaged(0, COUNT);
    const [ currentPage, setCurrentPage ] = React.useState(0);
    const [ currentPageToAdd, setCurrentPageToAdd ] = React.useState(0);
    const deleteHook = useDeleteStudentForm();
    const addHook = useAddStudentToForm();

    return(
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <span style={{ fontWeight: 'bold' }}>Estudiantes inscritos</span>
                    <hr />
                    <TableStudents
                        count={COUNT}
                        data={studentsHook.data}
                        currentPage={currentPage}
                        changePage={(page) => {
                            studentsHook.loadData(form, page, COUNT);
                            setCurrentPage(page);
                        }}
                        deleteStudent={(uuid) => {
                            deleteHook.remove(uuid, () => {
                                studentsHook.loadData(form, currentPage, COUNT);
                                studentsToAddHook.loadData(currentPageToAdd, COUNT);
                            });
                        }}
                    />                    
                </Col>
                <Col lg={1}>
                    <div className="flex-col flex-center" style={{ height: '100%' }}>
                        <div style={{ width: '5px', height: '100%', backgroundColor: '#42A341', borderRadius: '50%' }}>
                        </div>
                    </div>
                </Col>
                <Col lg={5}>
                    <span style={{ fontWeight: 'bold' }}>Estudiantes que pueden ser inscritos</span>
                    <hr />
                    <TableToAdd
                        count={COUNT}
                        data={studentsToAddHook.data}
                        currentPage={currentPageToAdd}
                        changePage={(page) => {
                            studentsToAddHook.loadData(page, COUNT);
                            setCurrentPageToAdd(page);
                        }}
                        addStudent={(uuid) => {
                            addHook.addStudent(uuid, form, () => {
                                studentsHook.loadData(form, currentPage, COUNT);
                                studentsToAddHook.loadData(currentPageToAdd, COUNT);
                            });
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default InfoStudents;