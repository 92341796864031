import React from "react";
import { buildEmptyForm, transformEntityForm } from "../../_events/form/model";
import { findFormByIdEvent } from "../../_events/form/find.event";
import { createFormEvent, editFormEvent, createFormWithStudentsEvent, createFormWithStudentsCopyEvent } from "../../_events/form/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";
import { useQuery } from "../../_utils/url_query_hook";

export const useFormCreateEdit = (semester) => {

    const [ entity, setEntity ] = React.useState(buildEmptyForm(semester));
    const query = useQuery();
    const id = query.get("id");

    const create = () => {
        createFormEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(json.data);
        })
        .catch(err => {
            alertError(err.message);
        });      
    }

    const createWithStudents = (semester, callBack = () => {} ) => {
        let body = {...entity};
        body.semester = semester;
        if(body.copy_form !== null && body.copy_form !== ''){
            createFormWithStudentsCopyEvent(body)
            .then(json => {
                if(! json.data.uuid){
                    alertError("Sin actividades definidas");
                } else {
                    alertSuccess("Guardado");
                    callBack();
                }            
            })
            .catch(err => {
                alertError(err.message);
            });
        } else {
            createFormWithStudentsEvent(body)
            .then(json => {
                if(! json.data.uuid){
                    alertError("Sin actividades definidas");
                } else {
                    alertSuccess("Guardado");
                    callBack();
                }            
            })
            .catch(err => {
                alertError(err.message);
            });
        }
    }

    const edit = () => {
        editFormEvent(entity.uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(id){
            findFormByIdEvent(id)
            .then(json => {
                if(json.data){
                    setEntity(transformEntityForm(json.data));
                }                
            })
            .catch(err => {
                alertError(err.message);
            });
        }
    }, [id]);

    return { entity, setEntity, create, edit, createWithStudents };
}