import { findAllTypePersonPaged, findTypePersonById, findAllTypePerson } from "../../_services/person_type.service";

export const findAllTypePersonPagedEvent = async (page, limit = 8) => {
    return await findAllTypePersonPaged(page, limit);
}

export const findTypePersonByIdEvent = async (id) => {
    return await findTypePersonById(id);
}

export const findAllTypePersonEvent = async () => {
    return await findAllTypePerson();
}