import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/competency-activity';

export const findCompetencyActivityById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const changeStateById = async (id, state) => {
    return await handleFetch(`${BASE_PATH}/change-state/${id}/${state}`, GET_OPTIONS);
}

export const findAllCompetencyActivity = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createCompetencyActivity = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editCompetencyActivity = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllCompetencyActivityPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllCompetencyActivityPagedByCompetency = async (id, page, limit) => {
    return await handleFetch(`${BASE_PATH}/comptency/all-paged/${id}/${page}/${limit}`, GET_OPTIONS);
}