export const validateActivityForm = (body) => {
    const { form, activity } = body;
    if(form === null || form === '') throw new Error("Planilla vacío");
    if(activity === null || activity === '') throw new Error("Actividad vacía");

}

export const transformEntityActivityForm = (entity) => {
    let newEnt = {...entity};
    return newEnt;
}

export const buildEmptyActivityForm = (form, term) => {
    return{
        form,
        activity: '',
        term
    };
}

export const buildActivityForm = (form, activity, term) => {
    return{
        form,
        activity,
        term
    };
}