import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { path_modules } from '../app_modules';
import { loginEvent } from '../../_events/users/login.event';
import { alertError } from '../../_utils/alerts';
import { enterKeyHandler } from '../../_utils/keyboard_events';

function LoginForm(){

    let navigate = useNavigate();

    const [ entity, setEntity ] = React.useState({ login: '', password: '' });
    const[ loading, setLoading ] = React.useState(false);

    const login = () => {
        setLoading(true);
        loginEvent(entity)
        .then(data => {
            setLoading(false);
            if(data.rol === 'ADMI') navigate(path_modules.content.children.parametrization.children.academic_concepts.fullPath);
            else if(data.rol === 'COOR' || data.rol === 'DOCE' || data.rol === 'SUPE') navigate(path_modules.content.children.operation.children.forms.fullPath);
        })
        .catch(err => {
            setLoading(false);
            alertError(err.message);
        });      
    }

    return(
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <div className='form-label'>Usuario</div>
                    <input 
                        className='vp-input' 
                        type='text'
                        value={ entity.login }
                        onChange={(e) => {
                            let newEnt = {...entity};
                            newEnt.login = e.target.value;
                            setEntity(newEnt);
                        }} 
                        onKeyDown={(e) => {
                            enterKeyHandler(e, login);
                        }}
                    />
                </Col>
                <Col sm={12}>
                    <div className='form-label'>Contraseña</div>
                    <input 
                        className='vp-input' 
                        type='password'
                        value={ entity.password }
                        onChange={(e) => {
                            let newEnt = {...entity};
                            newEnt.password = e.target.value;
                            setEntity(newEnt);
                        }}  
                        onKeyDown={(e) => {
                            enterKeyHandler(e, login);
                        }}
                    />
                </Col>
                <Col sm={12} style={{ marginTop: '15px' }}>
                    <div className='flex-col flex-center'>
                        {
                            loading ?
                            <div className='vp-spinner'></div>
                            :
                            <Button variant='success' onClick={login} size='sm' style={{ width: '100%' }}>
                                Ingresar
                            </Button>
                        }
                    </div>
                    
                </Col>
            </Row>
        </Container>
    );

}

export default LoginForm;