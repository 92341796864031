import React, { useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useUserCreateEdit } from '../../_hooks/user/useUserCreateEdit.hook';
import { useCreateUserRequest } from '../../_hooks/create_user_request/use-create-user-request.hook';
import { transformEntityCreateUserRequestToUser } from '../../_events/create_user_request/model';

function RegisterForm(){

    const createUserRequestHook = useCreateUserRequest();
    const creationHook = useUserCreateEdit();

    useEffect(() => {
        if(createUserRequestHook.data){
            creationHook.setEntity(transformEntityCreateUserRequestToUser(createUserRequestHook.data));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createUserRequestHook.data]);

    if(createUserRequestHook.loading) return <div className='flex-col flex-center'><div className='vp-spinner'></div></div>

    if(! createUserRequestHook.data) return <div className='flex-col flex-center'>Link erróneo</div>
    if(createUserRequestHook.data.active !== 1) return <div className='flex-col flex-center'>Link ya ha sido usado. Usuario registrado</div>

    return(
        <Container>
            <Row>
                <Col lg={12}>
                    <div className='form-label'>Nombre completo</div>
                    <input 
                        className='vp-input' 
                        type='text'
                        value={ creationHook.entity?.personObj?.name }
                        onChange={(e) => {
                            let newEnt = {...creationHook.entity};
                            newEnt.personObj.name = e.target.value;
                            creationHook.setEntity(newEnt);                                 
                        }}
                    />
                    <div className='form-label'>Correo</div>
                    <input 
                        className='vp-input' 
                        type='email'
                        value={ creationHook.entity?.personObj?.email }
                        onChange={(e) => {
                            let newEnt = {...creationHook.entity};
                            newEnt.personObj.email = e.target.value;
                            creationHook.setEntity(newEnt);                                
                        }}
                    />
                </Col>
                <Col lg={12}>
                <div className='form-label'>Usuario de acceso</div>
                    <input 
                        className='vp-input' 
                        type='text'
                        value={ creationHook.entity.login }
                        onChange={(e) => {
                            let newEnt = {...creationHook.entity};
                            newEnt.login = e.target.value;
                            creationHook.setEntity(newEnt);                             
                        }}
                    />
                    <div>
                        <div className='form-label'>Contraseña</div>
                        <input 
                            className='vp-input' 
                            type='password'
                            value={ creationHook.entity.password }
                            onChange={(e) => {
                                let newEnt = {...creationHook.entity};
                                newEnt.password = e.target.value;
                                creationHook.setEntity(newEnt);                             
                            }}
                        />
                    </div>
                    <Button onClick={() => creationHook.create()} variant="success" size="sm" style={{ width: '100%', margin: '20px 0px' }}>
                        Crear
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterForm;