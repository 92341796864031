import React from "react";
import { buildEmptyFormTemplate, transformEntityFormTemplate } from "../../_events/form_template/model";
import { findFormTemplateBySemesterEvent } from "../../_events/form_template/find.event";
import { createFormTemplateEvent, editFormTemplateEvent } from "../../_events/form_template/create.event";
import { alertError, alertSuccess } from "../../_utils/alerts";

export const useFormTemplateCreateEdit = (semester) => {

    const [ entity, setEntity ] = React.useState(buildEmptyFormTemplate(semester));

    const create = () => {
        createFormTemplateEvent(entity)
        .then(json => {
            alertSuccess("Guardado");
            setEntity(json.data);
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    const edit = () => {
        editFormTemplateEvent(entity.uuid, entity)
        .then(json => {
            alertSuccess("Actualizado");
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    React.useEffect(() => {
        if(semester){
            findFormTemplateBySemesterEvent(semester)
            .then(json => {
                if(json.data){
                    setEntity(transformEntityFormTemplate(json.data));
                }                
            })
            .catch(err => {
                alertError(err.message);
            })
        }
    }, [semester]);

    return { entity, setEntity, create, edit };
}