import React from "react";
import PagedTable from "../../../../widgets/tables/paged_table";
import { useAllRolesPaged } from "../../../../_hooks/roles/useAllRolesPaged.hook";
import AccessModal from "./access_modal";
import ActionsModal from "./actions_modal";

function Table(){

    const COUNT = 10;
    const dataPagedHook = useAllRolesPaged({ page: 0, limit: COUNT });
    const [ currentPage, setCurrentPage ] = React.useState(0);

    const columns = [
        {
            key: 'name',
            name: 'Nombre',
            label: true,
        },
        {
            key: '',
            name: 'Acciones',
            label: false,
            render: (param) => {
                if(param.cod !== 'ADMI')
                return(
                    <div className="flex-row">
                        <AccessModal rol={param.cod} />
                        <ActionsModal rol={param.cod} />
                    </div>
                );
                else
                return(<></>);
            }
        },
    ];

    const changePage = (page) => {
        setCurrentPage(page);
        dataPagedHook.loadData(page, COUNT);
    }

    return(
        <PagedTable
            columns={columns}
            data={dataPagedHook.data[0]}
            total={dataPagedHook.data[1]}
            dataPerPage={COUNT}
            currentPage={currentPage}
            onChangePage={(page) => changePage(page)}
        />
    );
}

export default Table;