import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { term_percents, terms_total_points } from "../../../../../_config/term_percents";

function TotalTermInfo({ data, term }){

    let totalTerm = 0;
    let totalTermPoints = 0;

    return(
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <strong style={{ fontSize: '0.9em' }}>Corte {term}</strong>
                </Col>
                <Col lg={12}>
                {
                    data.map((ap, key) => {
                        let totalAP = 0;
                        let totalPoints = 0;
                        return(
                            <div key={`ap_${key}`}>
                                <div style={{ fontWeight: 'bold', fontSize: '0.8em' }} >
                                    - {ap.name}                                        
                                </div>
                                {
                                    ap.concepts.map((c, key) => {
                                        let totalCompetency = 0;
                                        
                                        return(
                                            <div key={`c_${key}`}>
                                                <div style={{ marginLeft: '20px', fontWeight: 'bold', fontSize: '0.8em' }}>
                                                    - {c.name}                                
                                                </div>
                                                {
                                                    c.groups.map((g, key) => {
                                                        return(
                                                            <div key={`g_${key}`}>
                                                                {
                                                                    g.competencies.map((co, indexG) => {
                                                                        for (let index = 0; index < co.activities.length; index++) {
                                                                            let total = 0;
                                                                            const activity = co.activities[index];
                                                                            const evaluations = activity.forms[0]?.evaluations;
                                                                            const maxPoints = Number(activity.max_point) * Number(activity.evaluations_number);
                                                                            totalPoints += maxPoints;
                                                                            totalTermPoints += maxPoints;
                                                                            total += evaluations[0] ? Number(evaluations[0].value) : 0;
                                                                            total += evaluations[0] ? Number(evaluations[0].value2) : 0;
                                                                            total += evaluations[0] ? Number(evaluations[0].value3) : 0;
                                                                            total += evaluations[0] ? Number(evaluations[0].value4) : 0;
                                                                            total += evaluations[0] ? Number(evaluations[0].value5) : 0;
                                                                            totalCompetency += total;
                                                                            totalTerm += total;
                                                                            totalAP += total; 
                                                                        }
                                                                                                                                               
                                                                        return(
                                                                            <div key={`co_tt${indexG}`}>
                                                                                {
                                                                                    /*
                                                                                <div style={{ marginLeft: '40px', fontSize: '0.8em' }}>
                                                                                    - {co.name}                                  
                                                                                </div>
                                                                                <div style={{ marginLeft: '50px', marginTop: '15px' }} >
                                                                                <TableInfo
                                                                                    competency={co}
                                                                                />
                                                                                </div>
                                                                                    */
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <div style={{ marginLeft: '50px' }}>
                                                <Table responsive striped bordered>
                                                    <thead>
                                                        <tr>
                                                        {Array.from({length: 6}).map((t, index) => (
                                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#eee' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#eee' }} key={`th_${index}`}>{index === 5 ? Number(totalCompetency).toFixed(4) : ''}</th>
                                                        ))}
                                                        </tr>
                                                    </thead>
                                                </Table>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                                <hr />
                                <Table responsive striped bordered>
                                    <thead>
                                        <tr>
                                        {Array.from({length: 6}).map((t, index) => (
                                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#E2FFD6' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#E2FFD6' }} key={`th_${index}`}>{index === 5 ? Number(totalAP).toFixed(4) : ''}{index === 4 ?  ap.name : ''}{index === 0 ? `Total máximo ${totalPoints}` : ''}</th>
                                        ))}
                                        </tr>
                                    </thead>
                                </Table>
                                <hr />
                                <hr />
                            </div>
                            
                        );
                    })
                }
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? Number(totalTerm).toFixed(4) : '' }{index === 4 ? 'Total' : ''}{index === 0 ? `Total máximo ${terms_total_points[`${term}`]} (${totalTermPoints})` : ''}</th>
                        ))}
                        </tr>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? Number(totalTerm * 5 / terms_total_points[`${term}`]).toFixed(4) : '' }{index === 4 ? 'NOTA' : ''}{index === 0 ? `NOTA máxima 5` : ''}</th>
                        ))}
                        </tr>
                        <tr>
                        {Array.from({length: 6}).map((t, index) => (
                            <th style={ index !== 0 ? { fontSize: '0.8em', width: '120px', backgroundColor: '#B1FA93' } : { fontSize: '0.8em', width: '50%', backgroundColor: '#B1FA93' }} key={`th_${index}`}>{index === 5 ? Number(totalTerm * 5 / terms_total_points[`${term}`] * (term_percents[`${term}`] / 100)).toFixed(4) : '' }{index === 4 ? `${term_percents[`${term}`]}%` : ''}</th>
                        ))}
                        </tr>
                    </thead>
                </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default TotalTermInfo;