export const validateCompetencyGroup = (body) => {
    const { name, concept } = body;
    if(name === null || name === '') throw new Error("Nombre vacío");
    if(concept === null || concept === '') throw new Error("Concepto académico vacío");

}

export const transformEntityCompetencyGroup = (entity) => {
    let newEnt = {...entity};
    newEnt.concept = newEnt.concept?.uuid;
    return newEnt;
}

export const buildEmptyCompetencyGroup = () => {
    return{
        name: '',
        state: 1,
        concept: '',
    };
}