import { editUser, createUserAndPerson, editUserPassword } from "../../_services/user.service";
import { validateUser } from "./model";

export const createUserEvent = async (body) => {
    validateUser(body);
    return await createUserAndPerson(body);
}

export const editUserEvent = async (id, body) => {
    validateUser(body);
    return await editUser(id, body);
}

export const editUserPasswordEvent = async (body) => {
    if(body.password === null || body.password === '') throw new Error('Password vacío');
    return await editUserPassword(body);
}