import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS } from './base.service';

const BASE_PATH = '/create-user-request';

export const findCreateUserRequestById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const inactiveCreateUserRequestById = async (id) => {
    return await handleFetch(`${BASE_PATH}/inactive/${id}`, PUT_OPTIONS);
}

export const findAllCreateUserRequest = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createCreateUserRequest = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}