import React from 'react';
import { getActions } from '../_utils/storage_handler';

const ActionWrapper = (AuthComponent, action) => (
    class extends React.Component{
        render(){
            if(validateAction(action)){
                return(<AuthComponent {...this.props} />);
            } else {
                return(
                    <></>
                );
            }
        }
    }
);

const validateAction = (action) => {
    let actions = getActions();
    return actions.some( a => a === action);
}

export default ActionWrapper;