import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { copyEvaluationFromTerm } from "../../../../../_hooks/evaluation/copyEvaluationFromTerm.hook";

const terms = [
    "1","2","3"
];

function CopyTermModal({ formId, loadFunc, term }){

    const [show, setShow] = React.useState(false);
    const [termTo, setTermTo] = React.useState("0");

    const useCopyHook = copyEvaluationFromTerm(loadFunc);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    return(
        <div>
            <Button onClick={handleShow} variant="success" size="sm">
                Copiar Corte
            </Button>
            <Modal show={show} onHide={handleClose} size="sm">
                <ModalBody>
                    <div style={{ fontWeight: 'bold' }}>Corte a copiar</div>
                    <hr/>
                    <div style={{ margin: '10px 0' }}>
                        <select
                            style={{ width: '100%' }}
                            value={termTo ?? "0"}
                            onChange={(e) => {
                                setTermTo(e.target.value);
                            }}
                        >
                            <option value={"0"}>Selecciona el corte</option>
                            {
                                terms.filter( t => t !== term ).map((term, key) => {
                                    return(
                                        <option value={term} key={`option_term_${key}`}>
                                            {term}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    {
                        termTo !== "0" &&
                        <Button
                            style={{ width: '100%' }}
                            variant="success" size="sm"
                            onClick={() => {
                                useCopyHook.copyActivities(termTo, term, formId)
                            }}
                        >
                            Copiar actividades
                        </Button>
                    }
                </ModalBody>
            </Modal>
        </div>
    );

}

export default CopyTermModal;