import React from "react";
import { useQuery } from "../../../../../_utils/url_query_hook";
import { useFormById } from "../../../../../_hooks/form/useFormById.hook";
import TermsInfo from "./term_info";
import { Nav } from "react-bootstrap";
import TotalInfo from "./total_info";

function FormTabs(){

    const [key, setKey] = React.useState('1');
    const query = useQuery();
    const uuid = query.get("id");
    const formHook = useFormById(uuid);

    const changeTab = (k) => {
        setKey(k);
    }

    if(formHook.data) {
        return (
            <div>
                <div>
                    <Nav variant="tabs" defaultActiveKey="1">                
                        <Nav.Item>
                            <Nav.Link onClick={() => changeTab('1')} eventKey="1">Corte 1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => changeTab('2')} eventKey="2">Corte 2</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => changeTab('3')} eventKey="3">Corte 3</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => changeTab('4')} eventKey="4">Total</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div style={{ marginTop: '20px' }}>
                    {
                        key === '1' &&
                        <TermsInfo
                            term={key}
                            students={formHook.data? formHook.data.students : []}
                        />
                    }
                    {
                        key === '2' &&
                        <TermsInfo
                            term={key}
                            students={formHook.data? formHook.data.students : []}
                        />
                    }
                    {
                        key === '3' &&
                        <TermsInfo
                            term={key}
                            students={formHook.data? formHook.data.students : []}
                        />
                    }
                    {
                        key === '4' &&
                        <TotalInfo
                            students={formHook.data? formHook.data.students : []}
                        />
                    }
                </div>
            </div>
        );
    }
    else
    return (
        <></>
    );
}

export default FormTabs;