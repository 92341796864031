import { handleFetch, POST_OPTIONS, GET_OPTIONS, PUT_OPTIONS, DELETE_OPTIONS } from './base.service';

const BASE_PATH = '/student-form';

export const findStudentFormById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const deleteStudentFormById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, DELETE_OPTIONS);
}

export const findStudentFormByIdWithEvaluations = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/evaluations/${id}`, GET_OPTIONS);
}

export const findStudentFormByIdWithEvaluationsByTerm = async (id, term) => {
    return await handleFetch(`${BASE_PATH}/id/evaluations/term/${id}/${term}`, GET_OPTIONS);
}

export const findAllStudentForm = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const createStudentForm = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editStudentForm = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllStudentFormPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllStudentFormByFormPaged = async (form, page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/form/${form}/${page}/${limit}`, GET_OPTIONS);
}

export const downloadtudentFormProceduresExcelByForm = async (form) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/form/${form}`, GET_OPTIONS);
}

export const downloadtudentFormProceduresExcelByTwoForms = async (form1, form2) => {
    return await handleFetch(`${BASE_PATH}/excel/procedures/form1/form2/${form1}/${form2}`, GET_OPTIONS);
}

export const downloadtudentFormEvaluationsExcelByForm = async (form) => {
    return await handleFetch(`${BASE_PATH}/excel/evaluations/form/${form}`, GET_OPTIONS);
}