export const validateEvaluation = (body) => {
    const { form, term } = body;
    if(form === null || form === '') throw new Error("Planilla vacía");
    if(term === null || term === '') throw new Error("Corte vacío");

}

export const transformEntityEvaluation = (entity) => {
    let newEnt = {...entity};
    return newEnt;
}

export const buildEmptyEvaluation = (form, term) => {
    return{
        form,
        order_value: 1,
        value: 0,
        term
    };
}

export const buildEvaluate = (form, term, evaluations) => {
    return{
        form,
        evaluations,
        term
    };
}