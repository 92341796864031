import { downloadExcelByStudentFormEvent } from "../../_events/academic_parts/find.event";
import { alertError } from "../../_utils/alerts";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const useDownloadStudentExcel = () => {

    const loadBytes = (sf, name) => {
        downloadExcelByStudentFormEvent(sf)
        .then(json => {
            const bytes = json.data.bytes;
            if(bytes){
                downloadExcelFile(bytes, name);
            } else {
                alertError('Ocurrió un error');
            }
        })
        .catch(err => {
            alertError(err.message);
        });
    }

    return { loadBytes };
}